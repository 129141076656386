import { gql } from "@apollo/client";
import { InvitationResult } from "../types/results/invite-result";
import { AppApolloClient } from "../../contexts/Apollo";
import { GET_WORKSHOP_QUERY } from "../constants";

const query = gql`
  query GetInvitation($email: String, $slotId: String) {
    getInvitation(email: $email, slot_id: $slotId) {
      millisecondsToStart
      splitMillisecondsWaitingTime
      sessionOpeningTimeInMilliseconds
      serverTimestamp
      invitation {
        id
        email
        emails_count
        profile_id
        slot_id
        status
        session_group
        slot {
          id
          create_date
          update_date
          creator_id
          ics
          ics_uid
          key
          reminder_status
          schedule_date
          status
          type
          workshop_id
          workspace_id
          workspace {
            contactEmail
          }
          sessions {
            id
            create_date
            update_date
            complete_date
            session_key
            state
            status
            slot_id
            creator_id
            workshop_id
            workspace_id
          }
          invitations {
            email
            status
            session_group
            profile {
              id
              name
              headline
              email
              workspace {
                workspace_id
              }
            }
          }
          workshop {
            ${GET_WORKSHOP_QUERY}
          }
        }
      }
    }
  }
`;

export function getInvitation(
  client: AppApolloClient,
  variables: { email: string; slotId: string }
) {
  return client
    .query<InvitationResult>({ query, variables, fetchPolicy: "no-cache" })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.getInvitation;
    });
}
