import {
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { useMachine } from "@xstate/react";

import * as Form from "@radix-ui/react-form";
import * as HoverCard from "@radix-ui/react-hover-card";
import { Cross2Icon } from "@radix-ui/react-icons";

import { formatDate } from "date-fns";
import { getTitle } from "../../../../../utils";
import { getScheduleMachineDialogState } from "../../../../../utils/schedule";

import {
  adminDashboardScheduleDialogMachine,
  AdminDashboardScheduleState,
} from "../../../../../+xstate/machines/dashboard/admin-dashboard-schedule-dialog";
import * as adminDashboardScheduleDialogActions from "../../../../../+xstate/actions/dashboard/admin-dashboard-schedule-dialog";
import * as workspaceTagsActions from "../../../../../apollo-graphql/actions/workspace-tags";
import { Workshop } from "../../../../../types/contentful/workshop/workshop";
import { SlotType } from "../../../../../apollo-graphql/types/enums/slot-type";
import { Profile } from "../../../../../apollo-graphql/types/profile";
import { ScheduledDay, Slot } from "../../../../../apollo-graphql/types/slot";

import ScheduleContent from "./components/ScheduleContent/ScheduleContent";
import BulkScheduleDialogContent from "./components/BulkScheduleDialogContent/BulkScheduleDialogContent";
import Dialog from "../../../../Shared/Dialog/Dialog";
import Loader from "../../../../Shared/Loader/Loader";
import ScheduleDialogButtons, {
  IScheduleDialogState,
} from "./components/ScheduleDialogButtons/ScheduleDialogButtons";
import CopyToClipboardButton from "../../../../CopyToClipboardButton/CopyToClipboardButton";

import styles from "./ScheduleDialog.module.css";

export default memo(function (
  props: PropsWithChildren<{
    workshop: Workshop;
    profiles: Profile[] | null;
    slot: Slot | null;
    scheduleDialogMachine: ReturnType<
      typeof useMachine<typeof adminDashboardScheduleDialogMachine>
    >;
    continueButtonClickHandler: (data?: IScheduleDialogState) => void;
    closeDialogHandler: () => void;
    editMode?: boolean;
  }>
) {
  const {
    workshop,
    profiles,
    slot,
    scheduleDialogMachine,
    continueButtonClickHandler,
    closeDialogHandler,
    editMode,
  } = props;

  const [scheduleDialogState, scheduleDialogMachineSend] =
    scheduleDialogMachine;

  const [dialogState, setDialogState] = useState<IScheduleDialogState>({});
  const [scheduleDateError, setScheduleDateError] = useState<string>("");
  const [infoModalIsVisible, setInfoModalIsVisible] = useState(false);
  const footerRef = useRef<HTMLDivElement | null>(null);

  const {
    context: { workspaceId, workspaceTags, error },
  } = scheduleDialogState;
  const state = getScheduleMachineDialogState(scheduleDialogState);
  const isScheduledSession =
    slot &&
    slot?.type === SlotType.ALL &&
    new Date((slot.schedule_date as unknown as number) * 1000) > new Date();

  const slotUrl = useMemo(
    () => (slot ? `${window.location.origin}/session/slot/${slot.id}` : ""),
    [slot]
  );

  const conversationTitle = useMemo(
    () => (slot?.workshop ? getTitle(slot?.workshop) : ""),
    [slot?.workshop]
  );

  const toggleInfoModalVisibility = useCallback(
    () => setInfoModalIsVisible((prev) => !prev),
    []
  );

  const getBulkScheduleData = useCallback(() => {
    const { scheduledDays, selectedScheduledDays } = dialogState;
    if (!selectedScheduledDays) {
      return {};
    }

    return selectedScheduledDays?.reduce((acc, day) => {
      if (!scheduledDays || !scheduledDays[day]?.length) {
        return acc;
      }
      acc[day] = scheduledDays[day];
      return acc;
    }, {} as ScheduledDay);
  }, [dialogState]);

  const onButtonClickHandler = useCallback(
    (type: SlotType) => {
      if (type === SlotType.BULK && !editMode) {
        continueButtonClickHandler(dialogState);
        return;
      }

      if (
        dialogState.time &&
        dialogState.date &&
        dialogState.date === formatDate(new Date(), "yyyy-MM-dd") &&
        new Date(dialogState.time).getTime() < new Date().getTime()
      ) {
        setScheduleDateError("Please select a valid date.");
        return;
      }

      const newScheduleDate = new Date(
        `${dialogState.date}T${formatDate(
          new Date(dialogState.time!),
          "HH:mm"
        )}`
      );

      const data =
        editMode && slot
          ? {
              id: slot.id,
              newScheduleDate,
            }
          : { ...dialogState, type };
      continueButtonClickHandler(data);
    },
    [continueButtonClickHandler, dialogState, editMode, slot]
  );

  const title = useMemo(() => {
    if (state === AdminDashboardScheduleState.Start) {
      return "Start conversation";
    }
    if (state === AdminDashboardScheduleState.ScheduleReady) {
      return editMode ? "Reschedule conversation" : "Schedule conversation";
    }
    if (state === AdminDashboardScheduleState.ScheduleReadyBulk) {
      return `Bulk Schedule "${getTitle(workshop)}"`;
    }
    if (state === AdminDashboardScheduleState.Done) {
      return slot?.type === SlotType.SPLIT
        ? "Conversation scheduled"
        : slot?.type === SlotType.BULK
        ? "Slots scheduled"
        : "Start conversation";
    }
    return "";
  }, [slot?.type, state, workshop, editMode]);

  const buttonText = useMemo(() => {
    if (state === AdminDashboardScheduleState.Start) {
      return "Continue";
    }
    if (
      state === AdminDashboardScheduleState.ScheduleReady ||
      state === AdminDashboardScheduleState.ScheduleReadyBulk
    ) {
      return "Schedule slot";
    }
    if (state === AdminDashboardScheduleState.Done) {
      return "Close";
    }
    return "";
  }, [state]);

  const buttonDisabled = useMemo(() => {
    if (state === AdminDashboardScheduleState.Start) {
      return !dialogState.type;
    }
    if (state === AdminDashboardScheduleState.ScheduleReady) {
      return (
        !dialogState.date ||
        !dialogState.time ||
        !dialogState.profiles ||
        dialogState.profiles.length === 0
      );
    }
    if (state === AdminDashboardScheduleState.ScheduleReadyBulk) {
      return (
        !dialogState.startDate ||
        !dialogState.endDate ||
        !dialogState.scheduledDays ||
        !Object.keys(getBulkScheduleData()).length
      );
    }
    return false;
  }, [dialogState, state, getBulkScheduleData]);

  const scheduleSessionInfoContent = useMemo(() => {
    return (
      <HoverCard.Root
        open={infoModalIsVisible}
        onOpenChange={toggleInfoModalVisibility}
      >
        <HoverCard.Trigger asChild>
          <i className="fa fa-info-circle faded" />
        </HoverCard.Trigger>
        <HoverCard.Portal container={footerRef.current}>
          <HoverCard.Content
            className={styles.hoverCardContent}
            sideOffset={5}
            side="top"
          >
            <div className="hover-card-header">
              <i className="fa-regular fa-info-circle"></i>
              <p className="text bold"> Slot & Sessions</p>
            </div>
            <div className="hover-card-body">
              <p>For example, if you’d like to invite 20 users, then:</p>
              <ul>
                <li>
                  <strong>Slot</strong> is when you want to distribute them
                  automatically into separated sessions.
                </li>
                <li>
                  <strong>Session</strong> is when you want these users to be
                  together at the same time
                </li>
              </ul>
            </div>
            <HoverCard.Arrow className={styles.hoverCardArrow} />
            <button
              className="IconButton"
              aria-label="Close"
              onClick={() => setInfoModalIsVisible(false)}
            >
              <Cross2Icon />
            </button>
          </HoverCard.Content>
        </HoverCard.Portal>
      </HoverCard.Root>
    );
  }, [toggleInfoModalVisibility, infoModalIsVisible]);

  const content = useMemo(() => {
    if (
      state === AdminDashboardScheduleState.Creating ||
      state === AdminDashboardScheduleState.CreatingBulk ||
      state === AdminDashboardScheduleState.LoadScheduleData
    ) {
      return (
        <div className={styles.loadingContainer}>
          <Loader />
        </div>
      );
    }
    if (state === AdminDashboardScheduleState.Start) {
      return (
        <>
          <ScheduleDialogButtons
            dialogState={dialogState}
            onDialogSetState={setDialogState}
            error={error}
          />
          {error && (
            <div className={styles.errorContainer}>
              <p>{error}</p>
            </div>
          )}
        </>
      );
    }
    if (state === AdminDashboardScheduleState.ScheduleReady) {
      return (
        <ScheduleContent
          workspaceTags={workspaceTags}
          profiles={profiles}
          editMode={editMode}
          errorMessage={scheduleDateError || error}
          dialogState={dialogState}
          setDialogState={setDialogState}
        />
      );
    }
    if (state === AdminDashboardScheduleState.ScheduleReadyBulk) {
      return (
        <BulkScheduleDialogContent
          startDate={dialogState.startDate}
          endDate={dialogState.endDate}
          scheduledDays={dialogState.scheduledDays}
          setDialogState={setDialogState}
          errorMessage={error}
        />
      );
    }
    if (state === AdminDashboardScheduleState.Done) {
      return (
        <div>
          <div className={styles.workshopScheduledImageContainer}>
            <img
              alt="workshop-scheduled"
              src="/images/workshop-scheduled.svg"
            />
          </div>
          {(slot?.type === SlotType.SPLIT ||
            slot?.type === SlotType.BULK ||
            isScheduledSession) && (
            <span
              className="text"
              data-test-locator="schedule-dialog-slot-info"
            >
              Invitations for conversation "{conversationTitle}" were sent to
              all invitees.
              <br />
              <br />
              You will receive a reminder shortly before the conversation.
            </span>
          )}
          {slot && slot?.type !== SlotType.BULK && (
            <div className={styles.slotDetails}>
              <Form.Root>
                <Form.Field name="slotUrl">
                  <Form.Control
                    className="FormControl"
                    value={slotUrl}
                    readOnly
                    onClick={(event) =>
                      (event.target as HTMLInputElement).setSelectionRange(
                        0,
                        slotUrl.length
                      )
                    }
                    data-test-locator="schedule-dialog-schedule-slot-url"
                  />
                </Form.Field>
              </Form.Root>
              <CopyToClipboardButton
                copyText={slotUrl}
                displayText="Copy link to clipboard"
                className="secondary"
                hasIcon={false}
                data-test-locator="schedule-dialog-schedule-copy-to-clipboard-button"
              />
            </div>
          )}
        </div>
      );
    }

    return null;
  }, [
    state,
    dialogState,
    error,
    workspaceTags,
    profiles,
    editMode,
    scheduleDateError,
    slot,
    isScheduledSession,
    conversationTitle,
    slotUrl,
  ]);

  const dialogActionsContent = useMemo(() => {
    return (
      <div ref={footerRef} className={styles.footer}>
        {state === AdminDashboardScheduleState.Done && (
          <button
            className="btn ghost"
            type="button"
            onClick={() => continueButtonClickHandler(dialogState)}
            disabled={buttonDisabled}
            data-test-locator="schedule-dialog-continue-button"
          >
            {buttonText}
          </button>
        )}

        {editMode && slot ? (
          <>
            <button
              className="btn"
              type="button"
              onClick={() => onButtonClickHandler(slot.type)}
              disabled={buttonDisabled}
              data-test-locator="schedule-dialog-reschedule-button"
            >
              Reschedule
            </button>
          </>
        ) : (
          <>
            {slotUrl && (
              <Link
                className="btn"
                target="_blank"
                to={slotUrl}
                data-test-locator="schedule-dialog-open-conversation-button"
              >
                Open conversation
              </Link>
            )}
            {state === AdminDashboardScheduleState.ScheduleReady && (
              <>
                {scheduleSessionInfoContent}
                <button
                  className="btn ghost"
                  type="button"
                  onClick={() => onButtonClickHandler(SlotType.ALL)}
                  disabled={buttonDisabled}
                  data-test-locator="schedule-dialog-schedule-session-button"
                >
                  Schedule session
                </button>
                <button
                  className="btn"
                  type="button"
                  onClick={() => onButtonClickHandler(SlotType.SPLIT)}
                  disabled={buttonDisabled}
                  data-test-locator="schedule-dialog-schedule-split-button"
                >
                  {buttonText}
                </button>
              </>
            )}
            {state === AdminDashboardScheduleState.ScheduleReadyBulk && (
              <>
                {scheduleSessionInfoContent}
                <button
                  className="btn"
                  type="button"
                  onClick={() => onButtonClickHandler(SlotType.BULK)}
                  disabled={buttonDisabled}
                  data-test-locator="schedule-dialog-schedule-bulk-button"
                >
                  {buttonText}
                </button>
              </>
            )}
            {state === AdminDashboardScheduleState.Start && (
              <button
                className="btn"
                type="button"
                onClick={() => continueButtonClickHandler(dialogState)}
                disabled={buttonDisabled}
                data-test-locator="schedule-dialog-schedule-instant-button"
              >
                {buttonText}
              </button>
            )}
          </>
        )}
      </div>
    );
  }, [
    buttonDisabled,
    buttonText,
    continueButtonClickHandler,
    dialogState,
    editMode,
    onButtonClickHandler,
    scheduleSessionInfoContent,
    slot,
    slotUrl,
    state,
  ]);

  useEffect(() => {
    if (!editMode || !slot) return;

    const scheduleDate = Number(slot?.schedule_date) * 1000;
    const date = formatDate(scheduleDate, "yyyy-MM-dd");
    const time = formatDate(scheduleDate, "yyyy-MM-dd'T'HH:mm:ss");
    const profiles = (slot.invitations || []).map(
      ({ profile }) => profile
    ) as unknown as Profile[];

    const newState = {
      type: slot.type,
      date,
      time,
      profiles,
    };

    setDialogState(newState);
  }, [editMode, profiles, slot]);

  useEffect(() => {
    if (state === AdminDashboardScheduleState.Start && workspaceId) {
      scheduleDialogMachineSend(
        workspaceTagsActions.getWorkspaceTags({ workspaceId })
      );
    }
    if (state === AdminDashboardScheduleState.Start && editMode) {
      scheduleDialogMachineSend(
        adminDashboardScheduleDialogActions.selectWorkshopStart({
          type: SlotType.SPLIT,
        })
      );
    }
  }, [state, workspaceId, scheduleDialogMachineSend, editMode]);

  return (
    <Dialog
      open
      heading={title}
      onClose={closeDialogHandler}
      actionsContent={dialogActionsContent}
    >
      {content}
    </Dialog>
  );
});
