import { gql } from "@apollo/client";
import { SessionResult } from "../types/results/session-result";
import { AppApolloClient } from "../../contexts/Apollo";
import { GET_WORKSHOP_QUERY } from "../constants";

const queryWithSlotAndWorkshop = gql`
  query Query($sessionKey: String!, $group: Int) {
    getSession(session_key: $sessionKey, group: $group) {
      group
      serverTimestamp
      millisecondsToStart
      splitMillisecondsWaitingTime
      sessionOpeningTimeInMilliseconds
      session {
        complete_date
        completed_activities
        create_date
        creator_id
        id
        session_key
        state
        status
        slot {
          id
          create_date
          update_date
          creator_id
          ics
          ics_uid
          key
          reminder_status
          schedule_date
          status
          type
          sessions {
            id
            create_date
            update_date
            complete_date
            session_key
            state
            status
            slot_id
            creator_id
            workshop_id
            workspace_id
          }
          invitations {
            email
            status
            session_group
            profile {
              id
              name
              headline
              email
              workspace {
                workspace_id
              }
            }
          }

          workshop_id
          workspace_id
          workspace {
            contactEmail
          }
          workshop {
            ${GET_WORKSHOP_QUERY}
          }
      }
    }
  }
}
`;

const query = gql`
  query Query($sessionKey: String!, $group: Int) {
    getSession(session_key: $sessionKey, group: $group) {
      group
      millisecondsToStart
      splitMillisecondsWaitingTime
      sessionOpeningTimeInMilliseconds
      session {
        complete_date
        completed_activities
        create_date
        creator_id
        status
        id
        session_key
        state
      }
    }
  }
`;

export function getSession(
  client: AppApolloClient,
  variables: {
    sessionKey: string;
    group?: number;
    includeSlotAndWorkshop: boolean;
  }
) {
  const { includeSlotAndWorkshop, ...queryVariables } = variables;
  return client
    .query<SessionResult>({
      query: includeSlotAndWorkshop ? queryWithSlotAndWorkshop : query,
      variables: queryVariables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.getSession;
    });
}
