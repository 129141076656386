import {
  PropsWithChildren,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import cn from "classnames";

import { GlobalContext } from "../../contexts/Global";
import { AuthState, ProfileSettings } from "../../+xstate/machines/auth";
import { HeaderContext } from "../../contexts/Header";
import { generateThemeImageKey } from "../../utils";
import { stepsSelectors } from "../Guide/steps";

import { FetchImage } from "../Shared/FetchImage/FetchImage";
import EditProfileModal from "../Profile/EditProfileModal/EditProfileModal";
import ChangePasswordModal from "../Profile/ChangePasswordModal/ChangePasswordModal";
import IconButton from "../Shared/Buttons/IconButton/IconButton";
import TextEllipsis from "../Shared/TextEllipsis/TextEllipsis";
import Toast, { ToastType } from "../Shared/Toast/Toast";

import styles from "./Header.module.css";

export const HEADER_OUTLET = "AHAPLAY_HEADER_OUTLET";
export const GUIDE_OUTLET = "AHAPLAY_GUIDE_OUTLET";
export const INTERNET_CONNECTION_OUTLET = "AHAPLAY_INTERNET_CONNECTION_OUTLET";

export const HeaderOutlet = () => <div id={HEADER_OUTLET} />;
export const GuideOutlet = () => <div id={GUIDE_OUTLET} />;
export const InternetConnectionOutlet = () => (
  <div id={INTERNET_CONNECTION_OUTLET} style={{ display: "flex" }} />
);

export const HeaderPortal = ({ children }: PropsWithChildren) => {
  return createPortal(children, document.getElementById(HEADER_OUTLET)!);
};
export const GuidePortal = ({ children }: PropsWithChildren) => {
  return createPortal(children, document.getElementById(GUIDE_OUTLET)!);
};
export const InternetConnectionPortal = ({ children }: PropsWithChildren) => {
  return createPortal(
    children,
    document.getElementById(INTERNET_CONNECTION_OUTLET)!
  );
};

export default memo(function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    auth: {
      matches,
      updateProfileDialog,
      updateProfile,
      updateProfileMachineState,
      context: { profile, token, routeData },
    },
  } = useContext(GlobalContext);

  const {
    theme,
    title: headerContextTitle,
    useDefaultTheme,
  } = useContext(HeaderContext);

  const [logoSrc, setLogoSrc] = useState<string>("");
  const [userDropdownVisibility, setUserDropdownVisibility] =
    useState<boolean>(false);
  const themeLogoSpaceRef = useRef<HTMLDivElement | null>(null);

  const isLoggedIn =
    matches(AuthState.Authenticated) || matches(AuthState.TechnicalSetup);
  const isUpdateProfileDialogOpen = matches({
    [AuthState.Authenticated]: ProfileSettings.UpdateProfile,
  });
  const isChangePasswordDialogOpen = matches({
    [AuthState.Authenticated]: ProfileSettings.ChangePassword,
  });

  const currentTheme = useMemo(() => theme, [theme]);
  const defaultTheme = useMemo(
    () => profile?.workspace?.workspace?.theme,
    [profile?.workspace?.workspace?.theme]
  );

  const journeyTheme = useMemo(() => {
    if (!currentTheme) return null;
    if ("sys" in currentTheme) {
      return currentTheme?.fields?.icon?.fields?.file?.url;
    }
  }, [currentTheme]);

  const themeDefaultImageKey = useMemo(
    () => (defaultTheme ? generateThemeImageKey(defaultTheme.id) : null),
    [defaultTheme]
  );

  const hasSessionInstance = useMemo(
    () => pathname.includes("session/instance"),
    [pathname]
  );

  const headerTitle = useMemo(() => headerContextTitle, [headerContextTitle]);

  const exitLink = useMemo(() => {
    if (!hasSessionInstance) return "/";
    return `/session/thank-you/${pathname.replace("/session/instance/", "")}`;
  }, [hasSessionInstance, pathname]);

  const closeDialogHandler = useCallback(
    () => updateProfileDialog({ open: false }),
    [updateProfileDialog]
  );

  const showEditProfileModalHandler = useCallback(() => {
    updateProfileDialog({ open: true, type: "profile" });
    setUserDropdownVisibility(false);
  }, [updateProfileDialog]);

  const logoutHandler = useCallback(() => {
    navigate("/logout", { replace: true });
    setUserDropdownVisibility(false);
  }, [navigate]);

  const showChangePasswordDialogHandler = useCallback(() => {
    updateProfileDialog({ open: true, type: "password" });
  }, [updateProfileDialog]);

  const userDropdownContent = useMemo(() => {
    return userDropdownVisibility ? (
      <>
        <div
          className={styles.backdrop}
          onClick={() => setUserDropdownVisibility(false)}
        />
        <ul className={styles.userDropdownContainer}>
          <li className="dropdown-item" onClick={showEditProfileModalHandler}>
            <span className="dropdown-icon">
              <i className="fa fa-user" />
            </span>
            <span className="dropdown-text">Profile settings</span>
          </li>
          <li className="dropdown-item" onClick={logoutHandler}>
            <span className="dropdown-icon">
              <i className="fa fa-sign-out" />
            </span>
            <span className="dropdown-text">Logout</span>
          </li>
        </ul>
      </>
    ) : null;
  }, [userDropdownVisibility, showEditProfileModalHandler, logoutHandler]);

  const themeContent = useMemo(() => {
    return !logoSrc ? null : (
      <div className={styles.themeLogo}>
        <FetchImage
          src={logoSrc}
          token={journeyTheme ? undefined : token!}
          handleImageLoad={(width) => {
            if (!themeLogoSpaceRef.current) return;
            themeLogoSpaceRef.current.style.width = `${width}px`;
          }}
          handleImageError={() => {
            setLogoSrc("");
          }}
        />
      </div>
    );
  }, [journeyTheme, logoSrc, token]);

  const toastData = useMemo(() => {
    const isProfileUpdate =
      updateProfileMachineState.context?.input?.payload?.variables?.name;
    const description =
      updateProfileMachineState.value === "success"
        ? isProfileUpdate
          ? "Your profile information has been updated successfully!"
          : "Your password has been changed successfully!"
        : null;

    const title = isProfileUpdate ? "Edit Profile" : "Change Password";

    return {
      type: ToastType.SUCCESS,
      title,
      description,
    };
  }, [
    updateProfileMachineState.context?.input?.payload?.variables?.name,
    updateProfileMachineState.value,
  ]);

  useEffect(() => {
    if (!themeDefaultImageKey && !journeyTheme) return;
    const url = useDefaultTheme
      ? `/asset/${themeDefaultImageKey}`
      : `https:${journeyTheme}`;
    setLogoSrc(url);
  }, [currentTheme, useDefaultTheme, themeDefaultImageKey, journeyTheme]);

  return (
    <>
      {isUpdateProfileDialogOpen && profile && (
        <EditProfileModal
          profile={profile}
          updateProfile={updateProfile}
          closeDialogHandler={closeDialogHandler}
          showChangePasswordDialog={showChangePasswordDialogHandler}
        />
      )}

      {isChangePasswordDialogOpen && profile && (
        <ChangePasswordModal
          profileId={profile.id}
          updateProfile={updateProfile}
          closeDialogHandler={closeDialogHandler}
        />
      )}

      <div
        className={cn(
          styles.header,
          !isLoggedIn || routeData?.headerLogoBig ? "logoBig" : ""
        )}
      >
        <div className={styles.left}>
          <Link to="/">
            <img className={styles.logo} src="/images/logo.svg" alt="logo" />
          </Link>
          {!routeData?.hideHeaderTheme && (
            <>
              {isLoggedIn && (!!currentTheme || !!headerTitle) && (
                <div id={styles.headerSeparator} />
              )}
              {themeContent && (
                <div className={styles.themeLogoSpace} ref={themeLogoSpaceRef}>
                  {themeContent}
                </div>
              )}
              {isLoggedIn && headerTitle && (
                <h3 className={cn(styles.title, "secondary")}>
                  <TextEllipsis text={headerTitle} />
                </h3>
              )}
            </>
          )}

          <div className={styles.headerOutletContainer}>
            <HeaderOutlet />
          </div>
        </div>

        {profile?.workspace?.workspace?.name &&
          !routeData?.hideWorkspaceName &&
          !hasSessionInstance && (
            <h3>{`${profile?.workspace?.workspace?.name}'s Workspace`}</h3>
          )}

        {isLoggedIn && !routeData?.hideUserDropdown && (
          <div className={cn(styles.right, stepsSelectors.headerRight)}>
            {/* Temporary solution, until these features are implemented */}
            {/* <div className={styles.link}>Feedback</div> */}
            {/* <div className={styles.link}>Help</div> */}

            {hasSessionInstance && (
              <>
                <InternetConnectionOutlet />
                <GuideOutlet />
              </>
            )}

            <div className={styles.userProfile}>
              <IconButton
                iconName="fa-user"
                onClick={() => setUserDropdownVisibility(true)}
                variant="secondary"
                size="small"
              />
              {userDropdownContent}
            </div>
            {hasSessionInstance && (
              <Link
                className={cn("btn", "secondary", "danger", "small")}
                to={exitLink}
              >
                Exit
              </Link>
            )}
          </div>
        )}
      </div>
      <Toast
        type={toastData.type}
        title={toastData.title}
        description={toastData.description}
      />
    </>
  );
});
