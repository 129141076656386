import { assign, createMachine } from "xstate";
import * as socketActions from "../actions/socket";
import { ConnectionStrength } from "../../types/enums/connection-strength";
import "./session/workshop";

type AllActionCreators = typeof socketActions;
type AllActionCreatorKeys = keyof AllActionCreators;
type AllActions = ReturnType<AllActionCreators[AllActionCreatorKeys]>;

export enum SocketStates {
  Idle = "idle",
  Connecting = "connecting",
  Open = "open",
  Closed = "closed",
  Pending = "pending",
}

interface SocketContext {
  error: any;
  connectionStrength: ConnectionStrength | null;
}

const context: SocketContext = {
  error: null,
  connectionStrength: null,
};

type SocketMachineTypes = {
  context: SocketContext;
  events: AllActions;
};

export const socketMachine = createMachine({
  id: "socket",
  context,
  types: {} as SocketMachineTypes,
  initial: SocketStates.Idle,
  states: {
    [SocketStates.Idle]: {
      on: {
        [socketActions.connecting.type]: {
          target: [SocketStates.Connecting],
        },
      },
    },
    [SocketStates.Connecting]: {
      on: {
        [socketActions.opened.type]: {
          target: SocketStates.Open,
          actions: assign({
            error: null,
          }),
        },
        [socketActions.error.type]: {
          target: SocketStates.Closed,
          actions: assign({
            error: ({ event }) => event.payload.event,
          }),
        },
      },
    },
    [SocketStates.Open]: {
      on: {
        [socketActions.closed.type]: {
          target: SocketStates.Closed,
          actions: assign({
            error: ({ event }) => event.payload.event,
          }),
        },
        [socketActions.error.type]: {
          target: SocketStates.Closed,
          actions: assign({
            error: ({ event }) => event.payload.event,
          }),
        },
        [socketActions.pending.type]: {
          target: SocketStates.Pending,
        },
      },
    },
    [SocketStates.Pending]: {
      on: {
        [socketActions.closed.type]: {
          target: SocketStates.Closed,
          actions: assign({
            error: ({ event }) => event.payload.event,
          }),
        },
        [socketActions.error.type]: {
          target: SocketStates.Closed,
          actions: assign({
            error: ({ event }) => event.payload.event,
          }),
        },
        [socketActions.opened.type]: {
          target: SocketStates.Open,
          actions: assign({
            error: null,
          }),
        },
      },
    },
    [SocketStates.Closed]: {
      on: {
        [socketActions.retry.type]: {
          target: SocketStates.Connecting,
        },
        [socketActions.connecting.type]: {
          target: SocketStates.Connecting,
        },
      },
    },
  },
  on: {
    [socketActions.reset.type]: {
      target: `#socket.${SocketStates.Idle}`,
      actions: assign({
        error: null,
      }),
    },
    [socketActions.setConnectionStrength.type]: {
      actions: assign({
        connectionStrength: ({ event }) => event.payload.connectionStrength,
      }),
    },
  },
});
