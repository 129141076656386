import { memo } from "react";
import {
  SCHEDULE_WORKSHOPS_DISABLED,
  BULK_SCHEDULE_WORKSHOPS_DISABLED,
  MASTER_DOMAIN,
} from "../../../../../../../constants/global";
import cn from "classnames";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { SlotType } from "../../../../../../../apollo-graphql/types/enums";
import {
  DaysOfTheWeek,
  ScheduledDay,
} from "../../../../../../../apollo-graphql/types/slot";
import { Profile } from "../../../../../../../apollo-graphql/types";
import { IBaseTag } from "../../../../../../../apollo-graphql/types/workspace-tag";

import styles from "./ScheduleDialogButtons.module.css";

export interface IScheduleDialogState {
  type?: SlotType;
  date?: string;
  startDate?: Date;
  endDate?: Date;
  scheduledDays?: ScheduledDay;
  selectedScheduledDays?: DaysOfTheWeek[];
  time?: string;
  profiles?: Profile[];
  tags?: IBaseTag[];

  id?: string;
  newScheduleDate?: Date;
}

interface ScheduleDialogButtonsProps {
  dialogState: IScheduleDialogState;
  onDialogSetState: ({ type }: { type: SlotType }) => void;
  error: string;
}

const ScheduleDialogButtons = ({
  dialogState,
  onDialogSetState,
  error,
}: ScheduleDialogButtonsProps) => {
  const isMasterDomain = window.location.hostname === MASTER_DOMAIN;
  return (
    <RadioGroup.Root
      className={styles.radioGroupRoot}
      value={dialogState.type}
      onValueChange={(value) => onDialogSetState({ type: value as SlotType })}
    >
      {(isMasterDomain || !BULK_SCHEDULE_WORKSHOPS_DISABLED) && (
        <div
          className={styles.typeSelector}
          data-test-locator="schedule-dialog-schedule-type-bulk"
        >
          <RadioGroup.Item
            className={styles.radioGroupItem}
            value={SlotType.BULK}
            id={SlotType.BULK}
          >
            <RadioGroup.Indicator className={styles.radioGroupIndicator}>
              <i className={cn("fa-solid", "fa-check")}></i>
            </RadioGroup.Indicator>
          </RadioGroup.Item>
          <label className={styles.label} htmlFor={SlotType.BULK}>
            <h3 className="bold">Bulk Schedule</h3>
            <span className="text">
              Select this to set a multiple future time slots for the
              participants in this workshop.
            </span>
          </label>
        </div>
      )}
      {(isMasterDomain || !SCHEDULE_WORKSHOPS_DISABLED) && (
        <div
          className={styles.typeSelector}
          data-test-locator="schedule-dialog-schedule-type-split"
        >
          <RadioGroup.Item
            className={styles.radioGroupItem}
            value={SlotType.SPLIT}
            id={SlotType.SPLIT}
          >
            <RadioGroup.Indicator className={styles.radioGroupIndicator}>
              <i className={cn("fa-solid", "fa-check")}></i>
            </RadioGroup.Indicator>
          </RadioGroup.Item>
          <label className={styles.label} htmlFor={SlotType.SPLIT}>
            <h3 className="bold">Schedule</h3>
            <span className="text">
              Select this to set a conversation time for the participants. Find
              a slot that fits everyone's calendar, and lock it.
            </span>
          </label>
        </div>
      )}
      <div
        className={styles.typeSelector}
        data-test-locator="schedule-dialog-schedule-type-all"
      >
        <RadioGroup.Item
          className={styles.radioGroupItem}
          value={SlotType.ALL}
          id={SlotType.ALL}
        >
          <RadioGroup.Indicator className={styles.radioGroupIndicator}>
            <i className={cn("fa-solid", "fa-check")}></i>
          </RadioGroup.Indicator>
        </RadioGroup.Item>
        <label className={styles.label} htmlFor={SlotType.ALL}>
          <h3 className="bold">Start now</h3>
          <span className="text">
            Dive straight into the conversation. No waiting, just immediate
            access.
          </span>
        </label>
      </div>
    </RadioGroup.Root>
  );
};

export default memo(ScheduleDialogButtons);
