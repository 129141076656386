export const defaultPage = 1;
export const defaultPageSize = 20;

export function deserializePageParams(searchParams: URLSearchParams) {
  const params = Object.fromEntries(searchParams.entries());
  return params;
}

export function serializePageParams<T>(filters: T) {
  if (typeof filters !== "object" || filters === null) {
    return "";
  }

  const filteredParams = Object.fromEntries(
    Object.entries(filters).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  const serializedParams = new URLSearchParams(filteredParams).toString();
  return serializedParams;
}
