import React, {
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { formatDate } from "date-fns";

import { IScheduleDialogState } from "../ScheduleDialogButtons/ScheduleDialogButtons";
import { Profile } from "../../../../../../../apollo-graphql/types";
import AutoComplete, {
  IAutoCompleteItem,
} from "../../../../../../Shared/Autocomplete/AutoComplete";
import {
  IBaseTag,
  WorkspaceTag,
} from "../../../../../../../apollo-graphql/types/workspace-tag";

import DatePicker from "../../../../../../Shared/DatePicker/DatePicker";
import TimePicker from "../../../../../../Shared/TimePicker/TimePicker";

import styles from "../../ScheduleDialog.module.css";
import cn from "classnames";

export default memo(function ScheduleContent(
  props: PropsWithChildren<{
    profiles: Profile[] | null;
    workspaceTags: WorkspaceTag[];
    dialogState: IScheduleDialogState;
    errorMessage?: string;
    editMode?: boolean;
    setDialogState: (value: React.SetStateAction<IScheduleDialogState>) => void;
  }>
) {
  const {
    dialogState,
    editMode,
    profiles,
    errorMessage,
    setDialogState,
    workspaceTags,
  } = props;

  const onDateChangeHandler = useCallback(
    (date: Date | null) => {
      if (!date) return;

      setDialogState((prev) => ({
        ...prev,
        date: formatDate(date, "yyyy-MM-dd"),
      }));
    },
    [setDialogState]
  );

  const onTimeChangeHandler = useCallback(
    (date?: Date | null) => {
      if (!date) return;

      setDialogState((prev) => ({
        ...prev,
        time: formatDate(date, "yyyy-MM-dd'T'HH:mm:ss"),
      }));
    },
    [setDialogState]
  );

  const onTagsChanges = useCallback(
    (tags: IAutoCompleteItem[]) => {
      setDialogState((prev) => ({
        ...prev,
        tags: tags as IBaseTag[],
        profiles: (profiles || []).filter(({ workspaceTags }) => {
          if (!workspaceTags?.length) return false;
          return tags.find((t) => workspaceTags.find((pt) => pt.id === t.id));
        }),
      }));
    },
    [profiles, setDialogState]
  );

  const onEmailsChanges = useCallback(
    (emails: IAutoCompleteItem[]) => {
      setDialogState((prev) => ({
        ...prev,
        profiles: (profiles || []).filter((p) =>
          emails.find((e) => e.id === p.id)
        ),
      }));
    },
    [profiles, setDialogState]
  );

  const defaultTags = useMemo(
    () => (dialogState.tags || []).map((t) => t.text),
    [dialogState.tags]
  );

  const allEmails = useMemo(
    () => (profiles || []).map((p) => ({ id: p.id, text: p.email })),
    [profiles]
  );
  const defaultEmails = useMemo(
    () => (dialogState.profiles || [])?.map((p) => p.email),
    [dialogState.profiles]
  );

  useEffect(() => {
    if (dialogState.date && dialogState.time) return;
    const now = new Date();
    const date = formatDate(now, "yyyy-MM-dd");
    const time = formatDate(now, "yyyy-MM-dd'T'HH:mm:ss");

    setDialogState((prev) => ({
      ...prev,
      date,
      time,
    }));
  }, [dialogState.date, dialogState.time, setDialogState]);

  return (
    <>
      <div className={styles.line}>
        <div
          className={cn(styles.lineSection, "picker-container")}
          data-test-locator="schedule-dialog-date-picker-container"
        >
          <span className="text small bold strong picker-title">Date</span>
          <DatePicker
            onChange={onDateChangeHandler}
            value={dialogState.date}
            minDate={new Date()}
            popperPlacement="bottom-start"
          />
        </div>
        <div
          className={cn(styles.lineSection, "picker-container")}
          data-test-locator="schedule-dialog-time-picker-container"
        >
          <span className="text small bold strong picker-title">Time</span>
          <TimePicker
            value={dialogState.time ? new Date(dialogState.time) : new Date()}
            handleTimeChange={onTimeChangeHandler}
          />
        </div>
      </div>

      <div
        className={styles.line}
        data-test-locator="schedule-dialog-tag-container"
      >
        <AutoComplete
          label="Filter participants by tags"
          placeholder="Select a tag"
          items={workspaceTags}
          defaultValues={defaultTags}
          onChanges={onTagsChanges}
          readOnly={!!editMode}
          disableCreation
        />
      </div>
      <div
        className={styles.line}
        data-test-locator="schedule-dialog-participants-container"
      >
        <AutoComplete
          label="Participants"
          placeholder="Select user email"
          items={allEmails}
          defaultValues={defaultEmails}
          onChanges={onEmailsChanges}
          readOnly={!!editMode}
          disableCreation
        />
      </div>
      {errorMessage && (
        <div className={styles.errorContainer}>
          <p data-test-locator="schedule-dialog-error-message-container">
            {errorMessage}
          </p>
        </div>
      )}
    </>
  );
});
