import { PropsWithChildren, ReactNode, memo, useMemo, useState } from "react";
import cn from "classnames";

import styles from "./InfoBox.module.css";

export type InfoBoxVariant = "regular" | "danger";

const InfoBox = (
  props: PropsWithChildren<{
    title?: string | ReactNode;
    description?: string;
    isDismissible?: boolean;
    transparent?: boolean;
    className?: string;
    variant?: InfoBoxVariant;
    testLocatorName?: string;
  }>
) => {
  const {
    title,
    description,
    isDismissible,
    transparent,
    className,
    variant,
    testLocatorName,
  } = props;

  const [isDismissed, setIsDismissed] = useState(false);

  const titleContent = useMemo(() => {
    if (!title) return null;
    return typeof title === "string" ? (
      <p className={cn(styles.title, "text small bold")}>{title}</p>
    ) : (
      title
    );
  }, [title]);

  return !isDismissed ? (
    <div
      className={cn(styles.container, className, {
        [styles.transparent]: transparent,
        [styles.containerDanger]: variant === "danger",
      })}
      data-test-locator={testLocatorName}
    >
      <div className={styles.titleContainer}>
        <i className="fa-regular fa-circle-info" />
        {titleContent}
      </div>
      {description && (
        <div
          className={cn(
            styles.description,
            isDismissible && styles.isDismissible
          )}
        >
          {description && <p className="text small">{description}</p>}
        </div>
      )}
      {isDismissible && (
        <div className={styles.dismissButton}>
          <i
            className="fa fa-xmark icon-dismiss"
            onClick={() => setIsDismissed(true)}
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default memo(InfoBox);
