import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  NavLink,
  Navigate,
  useLocation,
  useMatches,
  useSearchParams,
} from "react-router-dom";

import cn from "classnames";

import { useMachine } from "@xstate/react";
import { GlobalContext } from "../../../../contexts/Global";
import { ApolloContext } from "../../../../contexts/Apollo";
import { AdminDashboardContext } from "../../../../contexts/AdminDashboard";

import {
  AdminDashboardScheduleState,
  adminDashboardScheduleDialogMachine,
} from "../../../../+xstate/machines/dashboard/admin-dashboard-schedule-dialog";
import { ongoingSessionMachine } from "../../../../+xstate/machines/ongoing-session";
import {
  RescheduleState,
  rescheduleInvitationMachine,
  rescheduleMachine,
} from "../../../../+xstate/machines/reschedule";
import {
  openRescheduleSlot,
  closeRescheduleSlot,
  reschedule,
} from "../../../../+xstate/actions/reschedule";

import * as adminDashboardScheduleDialogActions from "../../../../+xstate/actions/dashboard/admin-dashboard-schedule-dialog";
import * as actions from "../../../../+xstate/actions/ongoing-session";
import {
  editSlot,
  enterAdminSchedule,
} from "../../../../+xstate/actions/dashboard/admin-dashboard";

import {
  INFINITE_TIMER_TICK,
  useInfiniteTimer,
} from "../../../../hooks/useInfiniteTimer";
import withRouteConfig from "../../../../hocs/withRouteConfig";

import { parseSession } from "../../../../utils/parse-session";
import { getTeamName } from "../../../../utils/get-team-name";
import { calculateWorkshopDuration } from "../../../../utils";
import {
  getCurrentLevel,
  getCurrentActivity,
  getTotalLevels,
} from "../../../../utils/activities";

import { fetchTeamMembers } from "../../../../+xstate/actions/team-members";
import { tmDefaultFilteringState } from "../TeamMembers/TeamMembers";
import { SocketStates } from "../../../../+xstate/machines/socket";
import { useChildMachine } from "../../../../hooks/useChildMachine";

import { StandardSessionActivity } from "../../../../apollo-graphql/types/enums/standard-session-activity";
import { ISlotInvitation, Slot } from "../../../../apollo-graphql/types/slot";
import { formatSlotScheduleDate } from "../../../../utils/format-schedule-date";
import { hasAdminAccess } from "../../../../utils/profile";
import { InvitationStatus } from "../../../../types/enums/invitation-status";
import { SlotType } from "../../../../apollo-graphql/types/enums/slot-type";
import {
  AutoGenerateFilters,
  SlotStatus,
} from "../../../../apollo-graphql/types/enums";
import { WorkshopClock } from "../../../../helpers/workshop-clock";
import { SessionStateValue } from "../../../../apollo-graphql/types/session-state";
import { SessionStatus } from "../../../../apollo-graphql/types/enums/session-status";
import { FetchState } from "../../../../+xstate/machines/fetch-factory";
import { SortDirection } from "../../../../types/enums/sort-direction";
import { Workshop } from "../../../../types/contentful/workshop/workshop";
import {
  SCHEDULE_DISABLED,
  SORT_DIRECTION_QUERY_KEY,
} from "../../../../constants/global";
import {
  MY_SCHEDULE_ADMIN,
  MY_SCHEDULE_MEMBER,
  TEAM_SCHEDULE,
} from "../../../../constants/navigation";
import InvitationStatusIcon from "../../../Shared/InvitationStatusIcon/InvitationStatusIcon";
import InvitationContent from "./components/InvitationContent/InvitationContent";
import UserAvatar from "../../../Shared/UserAvatar/UserAvatar";
import WorkshopResultCard from "./components/WorkshopResultCard/WorkshopResultCard";
import ScheduleDialog from "../../Workshops/components/ScheduleDialog/ScheduleDialog";
import { IScheduleDialogState } from "../../Workshops/components/ScheduleDialog/components/ScheduleDialogButtons/ScheduleDialogButtons";
import Loader from "../../../Shared/Loader/Loader";
import NoSlotsFound from "../../../NoSlotsFound/NoSlotsFound";
import SkeletonLoader from "../../../Shared/SkeletonLoader/SkeletonLoader";
import RescheduleDialog from "../../Member/RescheduleDialog";
import Toast, { ToastType } from "../../../Shared/Toast/Toast";
import CopyToClipboardButton from "../../../CopyToClipboardButton/CopyToClipboardButton";

import styles from "./Schedule.module.css";

const invitationSortMap = {
  [InvitationStatus.AUTO_GENERATED]: 0,
  [InvitationStatus.ACCEPTED]: 1,
  [InvitationStatus.TENTATIVE]: 2,
  [InvitationStatus.DECLINED]: 3,
  [InvitationStatus.PENDING]: 4,
};

const slotSectionStatusMap = {
  "dashboard-schedule-upcoming": [SlotStatus.SCHEDULED, SlotStatus.ONGOING],
  "dashboard-schedule-history": [
    SlotStatus.COMPLETED,
    SlotStatus.CANCELLED,
    SlotStatus.NOT_ENOUGH_PLAYERS,
  ],
};

const rescheduleQueryParamKey = "rescheduleId";

const getCompletedSlotDuration = ({
  startTimestamp,
  endTimestamp,
}: {
  startTimestamp: number;
  endTimestamp: number;
}) => {
  const differenceInSeconds = endTimestamp - startTimestamp;

  const minutes = Math.floor(differenceInSeconds / 60);
  const seconds = differenceInSeconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
};

const filterSlotsWithOngoingSessions = (slots: Slot[]) =>
  slots?.filter((slot) => {
    const foundSessionInProgress = !!slot.sessions.find(
      (s) => s.status === SessionStatus.ONGOING
    );

    return !foundSessionInProgress && slot.status === SlotStatus.ONGOING
      ? null
      : slot;
  });

const Schedule = () => {
  const {
    auth: {
      context: { profile },
    },
  } = useContext(GlobalContext);
  const {
    teamMembers: { adminTeamMembersSend },
    send: adminSend,
    schedule: { getSlotsState, editSlotState },
  } = useContext(AdminDashboardContext);
  const { client, serverTimeEventTarget, socket } = useContext(ApolloContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();
  const matches = useMatches();

  // TODO move machines to admin machine !!!
  const [ongoingSessionState, ongoingSessionSend] = useMachine(
    ongoingSessionMachine,
    {
      input: { client },
    }
  );
  const scheduleDialogMachine = useMachine(
    adminDashboardScheduleDialogMachine,
    {
      input: { client },
    }
  );
  const [state, send] = useMachine(rescheduleMachine, {
    input: { client },
  });
  const [rescheduleInvitationMachineState] = useChildMachine(
    state,
    rescheduleInvitationMachine.id
  );

  const [scheduleDialogState, scheduleDialogSend] = scheduleDialogMachine;

  const { scheduledSlots, slotIdForReschedule, rescheduleResult } =
    state.context;

  const showRescheduleDialog =
    !state.matches({
      reschedule: RescheduleState.Idle,
    }) &&
    !(
      state.matches({
        reschedule: RescheduleState.Ready,
      }) && rescheduleResult
    );
  const isRescheduleDialogLoadingSlots = state.matches({
    reschedule: RescheduleState.LoadingSlots,
  });
  const isRescheduleDialogRescheduling = state.matches({
    reschedule: RescheduleState.Rescheduling,
  });

  const openRescheduleDialogHandler = useCallback(
    (slot: Slot) => {
      send(
        openRescheduleSlot({
          workshopId: slot.workshop_id,
          workspaceId: slot.workspace_id,
          slotId: slot.id,
        })
      );
    },
    [send]
  );

  const closeRescheduleDialogHandler = useCallback(() => {
    send(closeRescheduleSlot());
  }, [send]);

  const rescheduleHandler = useCallback(
    (invitationId: string, slot: Slot) => {
      send(
        reschedule({
          invitationId,
          newSlotId: slot.id,
        })
      );
    },
    [send]
  );

  /* Team member logic End */

  const [collapsedIds, setCollapsedIds] = useState<string[]>([]);
  const [sessionTimers, setSessionTimers] = useState<
    { sessionId: string; time: string; progress: number }[]
  >([]);

  const isOnMySchedule = matches.some(({ id }) =>
    [
      "dashboard-schedule-my",
      "dashboard-schedule-my-upcoming",
      "dashboard-schedule-my-history",
    ].includes(id)
  );

  const isOnTeamSchedule = matches.some(({ id }) =>
    [
      "dashboard-schedule-team",
      "dashboard-schedule-team-upcoming",
      "dashboard-schedule-team-history",
    ].includes(id)
  );

  const upcomingMatch = matches.find(({ id }) =>
    [
      "dashboard-schedule-upcoming",
      "dashboard-schedule-team-upcoming",
      "dashboard-schedule-my-upcoming",
    ].includes(id)
  );
  const historyMatch = matches.find(({ id }) =>
    [
      "dashboard-schedule-history",
      "dashboard-schedule-team-history",
      "dashboard-schedule-my-history",
    ].includes(id)
  );

  const title = isOnMySchedule
    ? hasAdminAccess(profile)
      ? MY_SCHEDULE_ADMIN
      : MY_SCHEDULE_MEMBER
    : TEAM_SCHEDULE;

  const slots = useMemo(
    () => getSlotsState?.context?.data?.slots || [],
    [getSlotsState?.context?.data?.slots]
  );

  const sortDirection = useMemo(
    () =>
      (searchParams.get(SORT_DIRECTION_QUERY_KEY) as SortDirection) ||
      SortDirection.ASC,
    [searchParams]
  );

  useEffect(() => {
    if (upcomingMatch || historyMatch) {
      const currentFilters =
        slotSectionStatusMap[
          upcomingMatch
            ? "dashboard-schedule-upcoming"
            : "dashboard-schedule-history"
        ];

      adminSend(
        enterAdminSchedule({
          slotStatuses: currentFilters,
          sortDirection,
          emails: isOnMySchedule ? [profile!.email] : undefined,
          autoGenerateFilters: isOnMySchedule
            ? AutoGenerateFilters.SlotsAndInvitations
            : AutoGenerateFilters.Invitation,
        })
      );
    }
  }, [
    adminSend,
    isOnMySchedule,
    profile,
    sortDirection,
    upcomingMatch,
    historyMatch,
  ]);

  const rescheduleDialogIsOpen =
    scheduleDialogState.matches(AdminDashboardScheduleState.Start) ||
    scheduleDialogState.matches(AdminDashboardScheduleState.LoadScheduleData) ||
    scheduleDialogState.matches(AdminDashboardScheduleState.ScheduleReady) ||
    scheduleDialogState.matches(AdminDashboardScheduleState.Done);

  const subscriptionSlotIds = useMemo(
    () =>
      slots
        .filter(({ sessions }) =>
          sessions.some((s) => s.status === SessionStatus.ONGOING)
        )
        .map(({ id }) => id),
    [slots]
  );

  const sessions = useMemo(() => {
    return slots.reduce(
      (acc, slot) => {
        const mappedSessions = slot.sessions.map((s) => ({
          workshopDuration: calculateWorkshopDuration(slot.workshop),
          key: s.session_key,
          sessionId: s.id,
          slotId: slot.id,
          value: parseSession(s.state),
        }));

        return acc.concat(...mappedSessions);
      },
      [] as {
        workshopDuration: number;
        key: string;
        sessionId: string;
        slotId: string;
        value: SessionStateValue | { context: null; value: null };
      }[]
    );
  }, [slots]);

  const ongoingSessions = useMemo(
    () =>
      ongoingSessionState.context.ongoingSessions?.map((os) => {
        const workshop = slots.find((s) => s.id === os.slotId)?.workshop;
        const workshopDuration = workshop
          ? calculateWorkshopDuration(workshop)
          : 0;

        return {
          ...os,
          workshopDuration,
        };
      }),
    [slots, ongoingSessionState.context.ongoingSessions]
  );

  const isFetchingOngoingSessions = useMemo(
    () => !socket.state.matches(SocketStates.Open),
    [socket]
  );

  const tickEventTarget = useInfiniteTimer({
    isReadyToInitialize: !isFetchingOngoingSessions,
    serverTimeEventTarget,
  });

  const sessionEndTimestampsString = useMemo(
    () =>
      ongoingSessions
        ?.map(
          ({ value, workshopDuration }) =>
            (value.context.startTimestamp || Infinity) + workshopDuration * 60
        )
        .join(","),
    [ongoingSessions]
  );

  const workspaceId = useMemo(() => profile!.workspace.workspace_id, [profile]);

  useEffect(() => {
    adminTeamMembersSend(
      fetchTeamMembers({
        workspaceId,
        filters: tmDefaultFilteringState,
      })
    );
  }, [adminTeamMembersSend, workspaceId]);

  const handleCollapse = useCallback(
    (slotId: string) => {
      if (collapsedIds.includes(slotId)) {
        setCollapsedIds(collapsedIds.filter((id) => id !== slotId));
      } else {
        setCollapsedIds([...collapsedIds, slotId]);
      }
    },
    [collapsedIds]
  );

  const expandedInvitations = useCallback((invitations: ISlotInvitation[]) => {
    const {
      acceptedInvitations,
      tentativeInvitations,
      declinedInvitations,
      pendingInvitations,
    } = invitations.reduce(
      (acc, { slot_id, profile, status }) => {
        const element = (
          <UserAvatar
            key={`${slot_id}-${status}-${profile.id}`}
            profile={profile}
            status={status}
          />
        );
        if (status === InvitationStatus.ACCEPTED) {
          acc.acceptedInvitations.push(element);
        }
        if (status === InvitationStatus.TENTATIVE) {
          acc.tentativeInvitations.push(element);
        }
        if (status === InvitationStatus.DECLINED) {
          acc.declinedInvitations.push(element);
        }
        if (status === InvitationStatus.PENDING) {
          acc.pendingInvitations.push(element);
        }

        return acc;
      },
      {
        acceptedInvitations: [],
        tentativeInvitations: [],
        declinedInvitations: [],
        pendingInvitations: [],
      } as {
        acceptedInvitations: JSX.Element[];
        tentativeInvitations: JSX.Element[];
        declinedInvitations: JSX.Element[];
        pendingInvitations: JSX.Element[];
      }
    );

    return (
      <>
        {!!acceptedInvitations.length && (
          <InvitationContent
            count={acceptedInvitations.length}
            status={InvitationStatus.ACCEPTED}
          >
            {acceptedInvitations}
          </InvitationContent>
        )}
        {!!tentativeInvitations.length && (
          <InvitationContent
            count={tentativeInvitations.length}
            status={InvitationStatus.TENTATIVE}
          >
            {tentativeInvitations}
          </InvitationContent>
        )}
        {!!declinedInvitations.length && (
          <InvitationContent
            count={declinedInvitations.length}
            status={InvitationStatus.DECLINED}
          >
            {declinedInvitations}
          </InvitationContent>
        )}
        {!!pendingInvitations.length && (
          <InvitationContent
            count={pendingInvitations.length}
            status={InvitationStatus.PENDING}
          >
            {pendingInvitations}
          </InvitationContent>
        )}
      </>
    );
  }, []);

  const tickHandler = useCallback(
    (event: Event) => {
      if (!ongoingSessions) return;

      const { currentServerTime, dispose } = (event as CustomEvent).detail as {
        currentServerTime: number;
        dispose: () => void;
      };

      const results: {
        sessionId: string;
        time: string;
        progress: number;
      }[] = ongoingSessions.map(({ value, sessionId, workshopDuration }) => {
        const startTimestamp = value.context.startTimestamp;

        if (startTimestamp === null) {
          const totalSeconds = workshopDuration * 60;
          const remainingMinutes = Math.floor(totalSeconds / 60);
          const remainingSeconds = totalSeconds % 60;
          return {
            sessionId,
            time: WorkshopClock.prototype.formatTime(
              remainingMinutes,
              remainingSeconds
            ),
            progress: 100,
          };
        }

        const endTimestamp = startTimestamp + workshopDuration * 60;
        const secondsRemaining = endTimestamp - currentServerTime;

        if (secondsRemaining < 0) {
          return {
            sessionId,
            time: WorkshopClock.prototype.formatTime(0, 0),
            progress: 0,
          };
        }

        const time =
          WorkshopClock.prototype.parseTickCountToString(secondsRemaining);
        return {
          sessionId,
          time,
          progress: (secondsRemaining / (workshopDuration * 60)) * 100,
        };
      });

      setSessionTimers(results);

      const sessionEndTimestamps = sessionEndTimestampsString
        ?.split(",")
        .map(Number);

      const allSessionsAreFinished = sessionEndTimestamps?.every(
        (t) => t <= currentServerTime
      );
      if (allSessionsAreFinished) dispose();
    },
    [ongoingSessions, sessionEndTimestampsString]
  );

  const changeSortDirection = useCallback(() => {
    if (slots.length <= 1) return;
    searchParams.set(
      SORT_DIRECTION_QUERY_KEY,
      sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams, sortDirection, slots]);

  const cancelSlotHandler = useCallback(
    (id: string) => {
      adminSend(
        editSlot({
          id,
          status: SlotStatus.CANCELLED,
        })
      );
    },
    [adminSend]
  );

  const rescheduleDialogHandler = useCallback(
    (data?: IScheduleDialogState) => {
      if (!data || !data.id || !data.newScheduleDate) return;
      adminSend(
        editSlot({
          id: data.id,
          schedule_date: data.newScheduleDate,
        })
      );
    },
    [adminSend]
  );

  const rescheduleCloseDialogHandler = useCallback(() => {
    searchParams.delete(rescheduleQueryParamKey);
    setSearchParams(searchParams);
    scheduleDialogSend(
      adminDashboardScheduleDialogActions.closeScheduleDialog()
    );
  }, [searchParams, setSearchParams, scheduleDialogSend]);

  const { selectedWorkshop, selectedSlot } = useMemo(() => {
    const result: {
      selectedWorkshop: Workshop | null;
      selectedSlot: Slot | null;
    } = { selectedWorkshop: null, selectedSlot: null };
    const rescheduleSlotId = searchParams.get(rescheduleQueryParamKey);
    if (!rescheduleSlotId) return result;
    const slot = slots?.find((s) => s.id === rescheduleSlotId);
    const workshop = slot?.workshop;

    if (!slot || !workshop) return result;

    return {
      selectedSlot: slot,
      selectedWorkshop: workshop,
    };
  }, [searchParams, slots]);

  const filtersContent = useMemo(() => {
    const upcomingTo = isOnMySchedule
      ? "/schedule/my/upcoming"
      : isOnTeamSchedule
      ? "/schedule/team/upcoming"
      : "/schedule/upcoming";
    const historyTo = isOnMySchedule
      ? "/schedule/my/history"
      : isOnTeamSchedule
      ? "/schedule/team/history"
      : "/schedule/history";
    return (
      <>
        <NavLink
          className={({ isActive }) =>
            `text small bold strong ${
              isActive ? "active primary" : "secondary"
            }`
          }
          to={upcomingTo}
        >
          Upcoming
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `text small bold strong ${
              isActive ? "active primary" : "secondary"
            }`
          }
          to={historyTo}
        >
          History
        </NavLink>
      </>
    );
  }, [isOnMySchedule, isOnTeamSchedule]);

  const headerContent = useMemo(() => {
    const sortIconClasses = cn(
      "fa",
      `fa-arrow-${
        sortDirection === SortDirection.ASC ? "up" : "down"
      }-short-wide`,
      "sort-icon"
    );
    return (
      <>
        <div
          className={cn(styles.lineSection, styles.date)}
          onClick={changeSortDirection}
        >
          <span className="text-subtitle palest">Date</span>
          <i className={sortIconClasses}></i>
        </div>
        <div className={cn(styles.lineSection, styles.workshopName)}>
          <span className="text-subtitle palest">Conversation name</span>
        </div>
        <div className={cn(styles.lineSection, styles.slotInvitations)}>
          <span className="text-subtitle palest">Invitations</span>
        </div>
        <div className={cn(styles.lineSection, styles.details)}>
          <span className="text-subtitle palest">Details</span>
        </div>
        <div className={cn(styles.lineSection, styles.actionColumn)} />
        <div className={cn(styles.lineSection, styles.collapseControlColumn)} />
      </>
    );
  }, [changeSortDirection, sortDirection]);

  const slotsContent = useMemo(() => {
    if (!slots || slots.length === 0)
      return <Loader className={styles.loaderContainer} />;
    return filterSlotsWithOngoingSessions(slots).map((slot) => {
      const isSlotCollapsed = collapsedIds.includes(slot.id);

      const invitations = (slot.invitations || []).sort(
        (a, b) => invitationSortMap[a.status] - invitationSortMap[b.status]
      );

      const inProgress = !!slot.sessions.find(
        (s) => s.status === SessionStatus.ONGOING
      );

      const slotSessions = (inProgress ? ongoingSessions : sessions)?.filter(
        (s) => s.slotId === slot.id
      );
      const workshopResults: JSX.Element[] = [];
      let currentSlotStatus = slot.status;

      slotSessions?.forEach(({ key, value, slotId, sessionId }) => {
        const [group, sessionKey] = key?.split(":") || [null, null];
        const data = value.context;
        if (data) {
          const teamName = getTeamName(
            value.context.activityResult,
            `No Team Name`
          );
          const currentInvitations = invitations.filter(
            ({ session_group }) => !!session_group && session_group === group
          );

          const slot = slots.find((s) => s.id === slotId);
          const currentLevel = getCurrentLevel(value);
          const currentActivity = getCurrentActivity(value.value, slot);
          const totalLevels = getTotalLevels(slot);
          const activityPlayersIds =
            data.currentActiveProfiles?.map((p) => p.profileId) || [];
          const status =
            value.value === StandardSessionActivity.ViewResults
              ? SlotStatus.COMPLETED
              : SlotStatus.ONGOING;

          if (status !== currentSlotStatus) {
            currentSlotStatus = status;
          }

          const levelProgress =
            (currentLevel /
              (slot?.workshop?.fields?.activities || []).filter(
                (activity) =>
                  !Object.values(StandardSessionActivity).includes(
                    activity.sys?.id as StandardSessionActivity
                  )
              ).length) *
            100;
          const currentSessionTimer = sessionTimers.find(
            (s) => s.sessionId === sessionId
          );
          const timeValue =
            status === SlotStatus.COMPLETED && data.startTimestamp
              ? getCompletedSlotDuration({
                  startTimestamp: data.startTimestamp,
                  endTimestamp: data.lastUpdatedTimestamp,
                })
              : currentSessionTimer?.time || "";

          workshopResults.push(
            <WorkshopResultCard
              key={key}
              sessionKey={sessionKey}
              group={group}
              teamName={teamName}
              currentLevel={currentLevel}
              currentActivity={currentActivity}
              totalLevels={totalLevels}
              activityPlayersIds={activityPlayersIds}
              status={status}
              invitations={currentInvitations}
              levelProgress={levelProgress}
              timeValue={timeValue}
            />
          );
        }
      });

      // Handles "Completed" slots showing in the "Upcoming" section
      if (inProgress === false && currentSlotStatus === SlotStatus.ONGOING) {
        return null;
      }

      const slotStatus = inProgress
        ? "Ongoing"
        : currentSlotStatus.toLocaleLowerCase().replaceAll("_", " ");

      const slotStatusClass = inProgress
        ? SlotStatus.ONGOING.toLocaleLowerCase()
        : currentSlotStatus.toLocaleLowerCase();

      const newQuery = `${rescheduleQueryParamKey}=${slot.id}`;
      const rescheduleLink = search ? `${search}&${newQuery}` : `?${newQuery}`;
      // can be rescheduled if the slot is not instant ?
      const canReschedule = slot.create_date !== slot.schedule_date;

      return (
        <div
          className={cn(styles.slot, isSlotCollapsed && "collapsed")}
          key={`slot-${slot.id}`}
        >
          <div className={styles.slotContent}>
            <div className={styles.slotLine}>
              <div className={cn(styles.lineSection, styles.date)}>
                {formatSlotScheduleDate(slot.schedule_date, "PPPP")}
              </div>
              <div className={cn(styles.lineSection, styles.workshopName)}>
                <div className="text">{slot?.workshop?.fields?.title}</div>
              </div>
              <div className={cn(styles.lineSection, styles.slotInvitations)}>
                <div className="invitations-badge">
                  <span className="text tiny bold">{invitations.length}</span>
                </div>
                <div className="invitations">
                  {invitations.map((invitation, idx) => (
                    <InvitationStatusIcon
                      className={styles.iconStatus}
                      key={`invitation-${idx}-${invitation.profile.id}-${invitation.slot_id}`}
                      status={invitation.status}
                    />
                  ))}
                </div>
              </div>
              <div className={cn(styles.lineSection, styles.details)}>
                <div className={cn("status", slotStatusClass, "text", "tiny")}>
                  {slotStatus}
                </div>
                <div className="type text tiny">
                  {slot.type === SlotType.ALL ? "Session" : "Slot"}
                </div>
              </div>
              <div className={cn(styles.lineSection, styles.actionColumn)}>
                {canReschedule && hasAdminAccess(profile) && (
                  <NavLink className="btn small secondary" to={rescheduleLink}>
                    Reschedule
                  </NavLink>
                )}
                {canReschedule &&
                  !hasAdminAccess(profile) &&
                  currentSlotStatus !== SlotStatus.CANCELLED &&
                  !historyMatch && (
                    <button
                      className="btn small secondary"
                      onClick={() => openRescheduleDialogHandler(slot)}
                    >
                      Reschedule
                    </button>
                  )}
              </div>
              <div
                className={cn(styles.lineSection, styles.collapseControlColumn)}
                onClick={() => handleCollapse(slot.id)}
              >
                <i className="icon fa fa-chevron-down secondary" />
              </div>
            </div>
            {isSlotCollapsed && (
              <div
                className={cn(
                  styles.slotCollapsedData,
                  workshopResults.length && styles.withSessions
                )}
              >
                {!!invitations.length && (
                  <div className="invitations">
                    {expandedInvitations(invitations)}
                  </div>
                )}
                <div className="teams">
                  {isFetchingOngoingSessions &&
                  slot.status === SlotStatus.ONGOING ? (
                    <div className={styles.workshopResultsSkeletons}>
                      <SkeletonLoader width={250} height={350} />
                    </div>
                  ) : workshopResults.length === 0 ? (
                    <div className={styles.noOngoingSessions}>
                      <h3>No ongoing sessions.</h3>
                    </div>
                  ) : (
                    <>{workshopResults}</>
                  )}
                </div>
                {!(
                  isFetchingOngoingSessions &&
                  slot.status === SlotStatus.ONGOING
                ) &&
                  !workshopResults.length && (
                    <div className="actions">
                      <div className="copy-container">
                        <CopyToClipboardButton
                          copyText={`${window.location.origin}/session/slot/${slot.id}`}
                          displayText={slot.id}
                          className="small ghost"
                        />
                      </div>
                      {currentSlotStatus !== SlotStatus.CANCELLED &&
                        currentSlotStatus !== SlotStatus.NOT_ENOUGH_PLAYERS && (
                          <div className="action-buttons">
                            <NavLink
                              to={`/session/slot/${slot.id}`}
                              target="_blank"
                              className="action-link"
                            >
                              <button className="btn small">Join</button>
                            </NavLink>

                            {hasAdminAccess(profile) && (
                              <>
                                <button
                                  className="btn small secondary"
                                  disabled
                                >
                                  Manage players
                                </button>
                                <button
                                  className="btn small secondary"
                                  disabled
                                >
                                  Resend invite
                                </button>

                                <button
                                  className="btn small secondary"
                                  onClick={() => cancelSlotHandler(slot.id)}
                                >
                                  Cancel
                                </button>
                              </>
                            )}
                          </div>
                        )}
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      );
    });
  }, [
    slots,
    collapsedIds,
    ongoingSessions,
    sessions,
    search,
    profile,
    isFetchingOngoingSessions,
    sessionTimers,
    historyMatch,
    expandedInvitations,
    handleCollapse,
    cancelSlotHandler,
    openRescheduleDialogHandler,
  ]);

  const content = useMemo(
    () =>
      filterSlotsWithOngoingSessions(slots).length === 0 &&
      !(getSlotsState?.value === FetchState.Fetching) ? (
        <NoSlotsFound
          type={upcomingMatch ? "upcoming" : "completed"}
          menu={isOnMySchedule ? "personal" : "team"}
        />
      ) : (
        <>
          <div className={styles.scheduleHeader}>{headerContent}</div>
          <div className={styles.slotsBody}>{slotsContent}</div>
        </>
      ),
    [
      getSlotsState?.value,
      headerContent,
      isOnMySchedule,
      slots,
      slotsContent,
      upcomingMatch,
    ]
  );

  const toastData = useMemo(() => {
    // TODO: Maybe we need to update success/failure messages.
    const description =
      rescheduleInvitationMachineState.value === FetchState.Success
        ? "Conversation successfully rescheduled."
        : rescheduleInvitationMachineState.value === FetchState.Failure
        ? "Rescheduling failed."
        : null;

    return {
      type:
        rescheduleInvitationMachineState.value === FetchState.Failure
          ? ToastType.ERROR
          : rescheduleInvitationMachineState.value === FetchState.Success
          ? ToastType.SUCCESS
          : ToastType.UNKNOWN,
      title: "Reschedule",
      description,
    };
  }, [rescheduleInvitationMachineState.value]);

  useEffect(() => {
    const rescheduleSlotId = searchParams.get(rescheduleQueryParamKey);

    if (
      (rescheduleDialogIsOpen && !rescheduleSlotId) ||
      (rescheduleDialogIsOpen &&
        editSlotState &&
        editSlotState.matches(FetchState.Success))
    ) {
      return void rescheduleCloseDialogHandler();
    }
    const workspaceId = profile!.workspace.workspace_id;
    const workshopId = slots.find((s) => s.id === rescheduleSlotId)?.workshop
      .sys.id;
    if (
      !rescheduleDialogIsOpen &&
      rescheduleSlotId &&
      workshopId &&
      workspaceId
    ) {
      return void scheduleDialogSend(
        adminDashboardScheduleDialogActions.openScheduleDialog({
          workshopId,
          workspaceId,
        })
      );
    }
  }, [
    rescheduleDialogIsOpen,
    profile,
    scheduleDialogSend,
    searchParams,
    slots,
    editSlotState,
    rescheduleCloseDialogHandler,
  ]);

  useEffect(() => {
    tickEventTarget.addEventListener(INFINITE_TIMER_TICK, tickHandler);
    return () => {
      tickEventTarget.removeEventListener(INFINITE_TIMER_TICK, tickHandler);
    };
  }, [tickEventTarget, tickHandler]);

  useEffect(() => {
    if (subscriptionSlotIds.length === 0) return;
    ongoingSessionSend(
      actions.ongoingSessionsSubscribe({
        slot_ids: subscriptionSlotIds,
      })
    );
  }, [ongoingSessionSend, subscriptionSlotIds]);

  useEffect(() => {
    if (
      state.matches({
        reschedule: RescheduleState.Ready,
      }) &&
      rescheduleResult
    ) {
      closeRescheduleDialogHandler();
      adminSend(
        enterAdminSchedule({
          slotStatuses: slotSectionStatusMap["dashboard-schedule-upcoming"],
          sortDirection,
          emails: [profile!.email],
        })
      );
    }
  }, [
    closeRescheduleDialogHandler,
    adminSend,
    rescheduleResult,
    state,
    profile,
    sortDirection,
  ]);

  if (!isOnMySchedule && !hasAdminAccess(profile)) {
    return <Navigate to="/schedule/my" />;
  }

  if (SCHEDULE_DISABLED) {
    return <Navigate to="/workshops" />;
  }

  if (!upcomingMatch && !historyMatch) {
    const to = isOnMySchedule
      ? "/schedule/my/upcoming"
      : isOnTeamSchedule
      ? "/schedule/team/upcoming"
      : "/schedule/upcoming";
    return <Navigate to={to} />;
  }

  return (
    <div className={styles.container}>
      <h3 className="bold">{title}</h3>
      <div className={styles.scheduleFilters}>{filtersContent}</div>
      <div className={styles.scheduleContainer}>{content}</div>

      {rescheduleDialogIsOpen && (
        <ScheduleDialog
          workshop={selectedWorkshop!}
          profiles={scheduleDialogState.context.profiles}
          slot={selectedSlot}
          scheduleDialogMachine={scheduleDialogMachine}
          continueButtonClickHandler={rescheduleDialogHandler}
          closeDialogHandler={rescheduleCloseDialogHandler}
          editMode
        />
      )}

      {showRescheduleDialog && profile && (
        <RescheduleDialog
          profileId={profile.id}
          slotIdForReschedule={slotIdForReschedule}
          scheduledSlots={scheduledSlots}
          isRescheduleDialogLoadingSlots={isRescheduleDialogLoadingSlots}
          isRescheduleDialogRescheduling={isRescheduleDialogRescheduling}
          rescheduleHandler={rescheduleHandler}
          closeDialogHandler={closeRescheduleDialogHandler}
        />
      )}
      <Toast
        type={toastData.type}
        title={toastData.title}
        description={toastData.description}
      />
    </div>
  );
};
export default memo(withRouteConfig(Schedule));
