import { PropsWithChildren, memo, useMemo } from "react";
import { ISlotInvitation } from "../../apollo-graphql/types/slot";

import UserAvatar from "../Shared/UserAvatar/UserAvatar";
import { Link } from "react-router-dom";
import { formatSlotScheduleDate } from "../../utils/format-schedule-date";
import { InvitationStatus } from "../../types/enums/invitation-status";
import { SlotStatus } from "../../apollo-graphql/types/enums";

import cn from "classnames";
import styles from "./Workshop.module.css";

type StatusMessageKeys = SlotStatus.CANCELLED | SlotStatus.NOT_ENOUGH_PLAYERS;

const slotStatusMessage: Record<StatusMessageKeys, string> & {
  default: string;
} = {
  [SlotStatus.CANCELLED]: "Sorry, this conversation has been cancelled on:",
  [SlotStatus.NOT_ENOUGH_PLAYERS]:
    "Sorry, this conversation has been cancelled due to low enrolment:",
  default: "The conversation will start soon:",
};

const Workshop = (
  props: PropsWithChildren<{
    workshopTitle: string;
    invitations: ISlotInvitation[];
    workshopDate: Date;
    millisecondsToStart: number;
    rescheduleButtonIsDisabled: boolean;
    workshopStartTimeText: string | null | undefined;
    status: SlotStatus;
    navigateToReschedule: () => void;
  }>
) => {
  const {
    workshopTitle,
    invitations,
    workshopDate,
    navigateToReschedule,
    rescheduleButtonIsDisabled,
    status,
  } = props;

  const workshopStartTimeText = useMemo(
    () => props.workshopStartTimeText || formatSlotScheduleDate(workshopDate),
    [props.workshopStartTimeText, workshopDate]
  );
  const slotIsCancelled =
    status === SlotStatus.CANCELLED || status === SlotStatus.NOT_ENOUGH_PLAYERS;

  const invitedUsersContent = useMemo(() => {
    return invitations
      ?.filter(({ status }) => status !== InvitationStatus.AUTO_GENERATED)
      .map(({ profile, status }) => {
        return (
          <UserAvatar
            profile={profile}
            status={status}
            showIcon={true}
            key={profile.id!}
          />
        );
      });
  }, [invitations]);

  return (
    <>
      <div>
        <h6 className="text">Welcome to group conversation</h6>
        <h1 data-test-locator="waiting-room-workshop-title">{workshopTitle}</h1>
      </div>
      <div className={cn(styles.workshopContent)}>
        <div className="workshop-info-container">
          <p className={styles.textInfo}>
            <i
              className={`${
                slotIsCancelled
                  ? "fa-regular fa-circle-info red"
                  : "fa fa-clock-o secondary"
              } `}
            />
            <span
              className={cn(slotIsCancelled ? "red" : "secondary")}
              data-test-locator="waiting-room-workshop-status"
            >
              {slotStatusMessage[status as StatusMessageKeys] ||
                slotStatusMessage.default}
            </span>
          </p>
          {workshopStartTimeText === props.workshopStartTimeText && (
            <p
              className={cn(styles.textInfo, "secondary")}
              data-test-locator="waiting-room-workshop-info"
            >
              Gathering players and distributing them in groups
            </p>
          )}
          <h3
            className="bold"
            data-test-locator="waiting-room-workshop-start-time"
          >
            {workshopStartTimeText}
          </h3>
        </div>
        <div>
          <p className={styles.textInfo}>Conversation players</p>
          <div
            className={styles.invitedUsers}
            data-test-locator="waiting-room-workshop-conversation-players"
          >
            {invitedUsersContent}
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <Link
          className={styles.workshopBtn}
          to="/"
          data-test-locator="waiting-room-go-to-dashboard-button"
        >
          Go to dashboard
        </Link>

        {!slotIsCancelled && (
          <button
            className={styles.rescheduleBtn}
            onClick={navigateToReschedule}
            disabled={rescheduleButtonIsDisabled}
            data-test-locator="waiting-room-reschedule-button"
          >
            Reschedule
          </button>
        )}
      </div>
    </>
  );
};

export default memo(Workshop);
