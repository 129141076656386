import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { ResendInvitationResult } from "../types/results/resend-invitation-result";
import { ResendInvitation } from "../types/resend-invitation";

const mutation = gql`
  mutation Mutation($email: String!, $grecaptchaTokens: [String]!) {
    resendInvitation(email: $email, grecaptchaTokens: $grecaptchaTokens) {
      message
      success
    }
  }
`;

export function resendInvitation(
  client: AppApolloClient,
  variables: ResendInvitation
) {
  return client
    .mutate<ResendInvitationResult>({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.resendInvitation;
    });
}
