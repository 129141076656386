import { memo, useContext, useMemo } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import {
  CONVERSATION_DISABLED_MEMBER,
  DASHBOARD_DISABLED_ADMIN,
  DASHBOARD_DISABLED_MEMBER,
  JOURNEYS_DISABLED,
  MILESTONES_DISABLED,
  SCHEDULE_DISABLED,
  TEAM_MEMBERS_DISABLED,
} from "../../constants/global";
import {
  DASHBOARD,
  CONVERSATIONS,
  JOURNEYS,
  MILESTONES,
  TEAM_SCHEDULE,
  MY_SCHEDULE_ADMIN,
  MY_SCHEDULE_MEMBER,
  AUTO_SCHEDULER,
  TEAM_MEMBERS,
  CONVERSATION_BUILDER,
} from "../../constants/navigation";
import { hasAdminAccess } from "../../utils/profile";
import { GlobalContext } from "../../contexts/Global";

import styles from "./Navigation.module.css";

const groupTitleClassnames = cn(
  styles.groupTitle,
  "text-subtitle",
  "secondary"
);
const MemberDashboardNavigation = () => {
  const {
    auth: {
      context: { profile },
    },
  } = useContext(GlobalContext);

  const isAdmin = useMemo(() => hasAdminAccess(profile), [profile]);

  const memberNavContent = useMemo(
    () => (
      <>
        {!DASHBOARD_DISABLED_MEMBER && (
          <>
            <NavLink
              className={cn(styles.link, "text")}
              to="/"
              data-test-locator="navigation-dashboard"
            >
              <i className={cn("icon", styles.icon, "fa", "fa-tachometer")} />
              <span className="text small bold">{DASHBOARD}</span>
            </NavLink>
          </>
        )}

        {!SCHEDULE_DISABLED && (
          <NavLink
            className={cn(styles.link, "text")}
            to="/schedule/my"
            data-test-locator="navigation-schedule-my"
          >
            <i
              className={cn(
                "icon",
                styles.icon,
                "fa",
                "fa-regular",
                "fa-calendar-range"
              )}
            />
            <span className="text small bold">{MY_SCHEDULE_MEMBER}</span>
          </NavLink>
        )}
        {(!DASHBOARD_DISABLED_MEMBER || !SCHEDULE_DISABLED) && (
          <div className={styles.divider} />
        )}

        {!CONVERSATION_DISABLED_MEMBER && (
          <div className={styles.group}>
            <span className={groupTitleClassnames}>Grow</span>
            <NavLink
              className={cn(styles.link, "text")}
              to="/workshops"
              data-test-locator="navigation-workshops"
            >
              <i
                className={cn(
                  "icon",
                  styles.icon,
                  "fa",
                  "fa-regular",
                  "fa-graduation-cap"
                )}
              />
              <span className="text small bold">{CONVERSATIONS}</span>
            </NavLink>
          </div>
        )}
      </>
    ),
    []
  );

  const adminNavContent = useMemo(
    () => (
      <>
        {!DASHBOARD_DISABLED_ADMIN && (
          <>
            <NavLink
              className={cn(styles.link, "text")}
              to="/"
              data-test-locator="navigation-dashboard"
            >
              <i className={cn("icon", styles.icon, "fa", "fa-tachometer")} />
              <span className="text small bold">{DASHBOARD}</span>
            </NavLink>

            <div className={styles.divider} />
          </>
        )}

        <div className={styles.group}>
          <span className={groupTitleClassnames}>Grow</span>
          <NavLink
            className={cn(styles.link, "text")}
            to="/workshops"
            data-test-locator="navigation-workshops"
          >
            <i
              className={cn(
                "icon",
                styles.icon,
                "fa",
                "fa-regular",
                "fa-graduation-cap"
              )}
            />
            <span className="text small bold">{CONVERSATIONS}</span>
          </NavLink>
          {!JOURNEYS_DISABLED && (
            <NavLink
              className={cn(styles.link, "text")}
              to="/journeys"
              data-test-locator="navigation-journeys"
            >
              <i
                className={cn("icon", styles.icon, "fa", "fa-graduation-cap")}
              />
              <span className="text small bold">{JOURNEYS}</span>
            </NavLink>
          )}

          {!MILESTONES_DISABLED && (
            <NavLink
              className={cn(styles.link, "text", styles.disabled)}
              to="/workshops"
              data-test-locator="navigation-workshops"
              onClick={(e) => e.preventDefault()}
            >
              <i className={cn("icon", styles.icon, "fa", "fa-trophy")} />
              <span className="text small bold">{MILESTONES}</span>
              <span className={cn(styles.soon, "badge", "secondary")}>
                soon
              </span>
            </NavLink>
          )}
        </div>
        <div className={styles.divider} />

        {(!SCHEDULE_DISABLED || !TEAM_MEMBERS_DISABLED) && (
          <>
            <div className={styles.group}>
              <span className={groupTitleClassnames}>Manage</span>
              {!SCHEDULE_DISABLED && (
                <NavLink
                  className={cn(styles.link, "text")}
                  to="/schedule/team"
                  data-test-locator="navigation-schedule-team"
                >
                  <i
                    className={cn(
                      "icon",
                      styles.icon,
                      "fa",
                      "fa-regular",
                      "fa-calendar-range"
                    )}
                  />
                  <span className="text small bold">{TEAM_SCHEDULE}</span>
                </NavLink>
              )}
              {!SCHEDULE_DISABLED && (
                <NavLink
                  className={cn(styles.link, "text")}
                  to="/schedule/my"
                  data-test-locator="navigation-schedule-team"
                >
                  <i
                    className={cn(
                      "icon",
                      styles.icon,
                      "fa",
                      "fa-regular",
                      "fa-calendar-range"
                    )}
                  />
                  <span className="text small bold">{MY_SCHEDULE_ADMIN}</span>
                </NavLink>
              )}
              {!SCHEDULE_DISABLED && (
                <NavLink
                  className={cn(styles.link, "text", styles.disabled)}
                  to="/schedule"
                  onClick={(e) => e.preventDefault()}
                  data-test-locator="navigation-schedule"
                >
                  <i
                    className={cn(
                      "icon",
                      styles.icon,
                      "fa",
                      "fa-regular",
                      "fa-calendar-clock"
                    )}
                  />
                  <span className="text small bold">{AUTO_SCHEDULER}</span>
                  <span className={cn(styles.soon, "badge", "secondary")}>
                    soon
                  </span>
                </NavLink>
              )}
              {!TEAM_MEMBERS_DISABLED && (
                <NavLink
                  className={cn(styles.link, "text")}
                  to="/team-members"
                  data-test-locator="navigation-team-members"
                >
                  <i
                    className={cn(
                      "icon",
                      styles.icon,
                      "fa",
                      "fa-regular",
                      "fa-user-group-simple"
                    )}
                  />
                  <span className="text small bold">{TEAM_MEMBERS}</span>
                </NavLink>
              )}
            </div>

            <div className={styles.divider} />
          </>
        )}

        <div className={styles.group}>
          <span className={groupTitleClassnames}>Create</span>
          <NavLink
            className={cn(styles.link, "text", styles.disabled)}
            to="/"
            onClick={(e) => e.preventDefault()}
            data-test-locator="navigation-conversation-builder"
          >
            <i
              className={cn(
                "icon",
                styles.icon,
                "fa",
                "fa-regular",
                "fa-shapes"
              )}
            />
            <span className="text small bold">{CONVERSATION_BUILDER}</span>
            <span className={cn(styles.soon, "badge", "secondary")}>soon</span>
          </NavLink>
        </div>
      </>
    ),
    []
  );

  return (
    <div className={styles.container}>
      {isAdmin ? adminNavContent : memberNavContent}
    </div>
  );
};
export default memo(MemberDashboardNavigation);
