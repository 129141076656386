import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";

import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition, Observable } from "@apollo/client/utilities";
import { ClientOptions, createClient } from "graphql-ws";
import { SocketStates, socketMachine } from "../+xstate/machines/socket";

import {
  closed,
  connected,
  connecting,
  error,
  opened,
  retry,
  pending,
  setConnectionStrength,
} from "../+xstate/actions/socket";
import { useMachine } from "@xstate/react";
import { apolloSubscriptionUri, apolloUri } from "../constants/endpoints";
import { ConnectionStrength } from "../types/enums/connection-strength";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { retryRequestIf } from "../utils/skip-retry";
import { ApolloServerErrorCodes } from "../types/server-error";
import { GraphQLFormattedError } from "graphql/error";

const PENDING_TIMEOUT_MS = 5_000;

const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: retryRequestIf,
  },
  delay: {
    initial: 1000,
    max: 3000,
    jitter: true,
  },
});

export type AppApolloClient = ApolloClient<NormalizedCacheObject>;

type SocketMachineState = ReturnType<
  typeof useMachine<typeof socketMachine>
>["0"];

export const SERVER_TIME_UPDATE = "SERVER_TIME_UPDATE" as const;
export const highConnectionThreshold = 150;
export const normalConnectionThreshold = 300;

const httpLink = createHttpLink({
  uri: apolloUri,
});

type ApolloContextValue = {
  client: AppApolloClient;
  setToken(value: string | null): void;
  socket: { state: SocketMachineState };
  serverTimeEventTarget: EventTarget;
  socketEventTarget: EventTarget;
  graphqlErrorEventTarget: EventTarget;
};

export const ApolloContext = createContext<ApolloContextValue>({} as any);

type Handlers = {
  shouldRetry: ClientOptions["shouldRetry"] | null;
  connectionParams: ClientOptions["connectionParams"] | null;
  on: Partial<ClientOptions["on"]> | null;
};

export const graphQLError = "GraphQLError" as const;

export enum SocketEvents {
  MESSAGE = "message",
  OPENED = "opened",
  CLOSED = "closed",
  ERROR = "error",
  CONNECTED = "connected",
  CONNECTING = "connecting",
  PING = "ping",
  PONG = "pong",
  PENDING = "pending",
}

let pingSentTime: number | undefined = undefined;
let pingPendingTimeout: number | null = null;
let connectionStrength = ConnectionStrength.Normal;

export const ApolloProvider = (props: PropsWithChildren) => {
  const [socketState, socketSend, socketActor] = useMachine(socketMachine);

  const token = useRef<string | null>();
  const handlers = useRef<Handlers>({
    shouldRetry: null,
    connectionParams: null,
    on: null,
  });

  const serverTimeEventTarget = useMemo(() => new EventTarget(), []);
  const socketEventTarget = useMemo(() => new EventTarget(), []);
  const graphqlErrorEventTarget = useMemo(() => new EventTarget(), []);

  useEffect(() => {
    // TODO: REMOVE CONSOLE LOGS
    console.log("EFFECT");
    handlers.current.shouldRetry = function shouldRetry() {
      const shouldRetry = !socketState.matches(SocketStates.Idle);
      console.log("RETRY HANDLER", shouldRetry, socketState);
      if (shouldRetry) socketSend(retry());
      return shouldRetry;
    };
  }, [socketSend, socketState]);

  useEffect(() => {
    handlers.current.connectionParams = function () {
      return {
        Authorization: token.current ? `Bearer ${token.current}` : "",
      };
    };
  }, []);

  const setPingPendingTimeout = useCallback(() => {
    if (pingPendingTimeout) {
      clearTimeout(pingPendingTimeout);
      pingPendingTimeout = null;
    }

    pingPendingTimeout = setTimeout(() => {
      socketSend(pending());
      socketEventTarget.dispatchEvent(new CustomEvent(SocketEvents.PENDING));
      socketActor.send(
        setConnectionStrength({
          connectionStrength: ConnectionStrength.Unknown,
        })
      );
    }, PENDING_TIMEOUT_MS) as any as number;
  }, [socketActor, socketEventTarget, socketSend]);

  useMemo(() => {
    handlers.current.on = {
      message: (message) => {
        if (message.type === "pong" && "serverTime" in message) {
          serverTimeEventTarget.dispatchEvent(
            new CustomEvent(SERVER_TIME_UPDATE, { detail: message.serverTime })
          );
        }

        socketEventTarget.dispatchEvent(
          new CustomEvent(SocketEvents.MESSAGE, { detail: message })
        );
      },
      opened: () => {
        console.log("SOCKET OPENED");
        socketSend(opened());
        socketActor.send(
          setConnectionStrength({
            connectionStrength: ConnectionStrength.Normal,
          })
        );

        socketEventTarget.dispatchEvent(new CustomEvent(SocketEvents.OPENED));
      },
      closed: (event) => {
        socketSend(closed({ event }));
        socketActor.send(
          setConnectionStrength({
            connectionStrength: ConnectionStrength.Unknown,
          })
        );

        socketEventTarget.dispatchEvent(
          new CustomEvent(SocketEvents.CLOSED, { detail: event })
        );
      },
      error: (event) => {
        socketSend(error({ event }));
        socketActor.send(
          setConnectionStrength({
            connectionStrength: ConnectionStrength.Unknown,
          })
        );

        socketEventTarget.dispatchEvent(
          new CustomEvent(SocketEvents.ERROR, { detail: event })
        );
      },
      connected: () => {
        console.log("SOCKET CONNECTED");

        socketSend(connected());
        socketActor.send(
          setConnectionStrength({
            connectionStrength: ConnectionStrength.Normal,
          })
        );

        socketEventTarget.dispatchEvent(
          new CustomEvent(SocketEvents.CONNECTED)
        );
      },
      connecting: () => {
        console.log("SOCKET CONNECTING");

        socketSend(connecting());
        socketActor.send(
          setConnectionStrength({
            connectionStrength: ConnectionStrength.Unknown,
          })
        );

        socketEventTarget.dispatchEvent(
          new CustomEvent(SocketEvents.CONNECTING)
        );
      },
      ping: () => {
        pingSentTime = Date.now();

        setPingPendingTimeout();

        socketEventTarget.dispatchEvent(new CustomEvent(SocketEvents.PING));
      },
      pong: () => {
        if (pingPendingTimeout) {
          clearTimeout(pingPendingTimeout);
          pingPendingTimeout = null;
        }

        const roundTripTime = Date.now() - pingSentTime!;
        if (roundTripTime <= highConnectionThreshold) {
          connectionStrength = ConnectionStrength.High;
        } else if (roundTripTime <= normalConnectionThreshold) {
          connectionStrength = ConnectionStrength.Normal;
        } else {
          connectionStrength = ConnectionStrength.Slow;
        }
        const snapshot = socketActor.getSnapshot();
        if (snapshot.context.connectionStrength !== connectionStrength) {
          socketActor.send(setConnectionStrength({ connectionStrength }));
        }

        socketEventTarget.dispatchEvent(new CustomEvent(SocketEvents.PONG));

        if (socketState.matches(SocketStates.Pending)) {
          socketSend(opened());
        }
      },
    };
  }, [
    serverTimeEventTarget,
    setPingPendingTimeout,
    socketActor,
    socketEventTarget,
    socketSend,
    socketState,
  ]);

  const client = useMemo(() => {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      const customErrorEvents: CustomEvent[] = [];
      if (graphQLErrors) {
        for (let i = 0; i < graphQLErrors.length; i++) {
          const error = graphQLErrors[i] as GraphQLFormattedError & {
            code: ApolloServerErrorCodes;
          };
          if (error?.code === ApolloServerErrorCodes.TOKEN_EXPIRED) {
            return new Observable((observer) => {
              const renewTokensCallback = (reason?: any) => {
                observer.error(
                  new ApolloError({
                    graphQLErrors,
                    networkError,
                  })
                );
                observer.complete();
              };

              const event = new CustomEvent(graphQLError, {
                detail: {
                  event: error,
                  renewTokensCallback,
                },
              });

              graphqlErrorEventTarget.dispatchEvent(event);
            });
          }
          customErrorEvents.push(
            new CustomEvent(graphQLError, {
              detail: {
                event: error,
              },
            })
          );
        }
        customErrorEvents.forEach((event) => {
          graphqlErrorEventTarget.dispatchEvent(event);
        });
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }

      return new Observable((observer) => {
        observer.error(
          new ApolloError({
            graphQLErrors,
            networkError,
          })
        );
        observer.complete();
      });
    });

    const wsLink = new GraphQLWsLink(
      createClient({
        url: apolloSubscriptionUri,
        shouldRetry: (errOrCloseEvent) => {
          if (
            (errOrCloseEvent as CloseEvent).wasClean ||
            !handlers.current.shouldRetry
          )
            return false;
          return handlers.current.shouldRetry(errOrCloseEvent);
        },
        connectionParams: () => {
          if (!handlers.current.connectionParams) return;
          if (typeof handlers.current.connectionParams === "function")
            return handlers.current.connectionParams();
          return handlers.current.connectionParams;
        },
        on: handlers.current.on || undefined,
        keepAlive: 1000,
        retryAttempts: Infinity,
      })
    );

    const authLink = setContext((_, { headers }) => {
      const result = (typeof handlers.current.connectionParams === "function"
        ? handlers.current.connectionParams()
        : handlers.current.connectionParams) || { Authorization: undefined };

      if (result instanceof Promise)
        return result.then((result) => {
          const { Authorization } = result || { Authorization: undefined };
          return {
            headers: {
              ...headers,
              Authorization,
              "Apollo-Require-Preflight": "true",
            },
          };
        });

      const { Authorization } = result;

      return {
        headers: {
          ...headers,
          Authorization,
        },
      };
    });

    const mutationSuccessLink = new ApolloLink((operation, forward) => {
      if (
        operation.query.definitions.some(
          (definition) =>
            definition.kind === "OperationDefinition" &&
            definition.operation === "mutation"
        )
      ) {
        return forward(operation).map((response) => {
          setPingPendingTimeout();

          return response;
        });
      }
      return forward(operation);
    });

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      ApolloLink.from([
        retryLink,
        errorLink,
        authLink,
        mutationSuccessLink,
        httpLink,
      ])
    );

    const client = new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache(),
    });

    return client;
  }, [graphqlErrorEventTarget, setPingPendingTimeout]);

  const value = useMemo(() => {
    const value: ApolloContextValue = {
      client,
      setToken(tokenValue: string | null) {
        token.current = tokenValue;
      },
      socket: { state: socketState },
      serverTimeEventTarget,
      socketEventTarget,
      graphqlErrorEventTarget,
    };
    return value;
  }, [
    client,
    graphqlErrorEventTarget,
    serverTimeEventTarget,
    socketEventTarget,
    socketState,
  ]);

  return (
    <ApolloContext.Provider value={value}>
      {props.children}
    </ApolloContext.Provider>
  );
};
