import {
  PropsWithChildren,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { useMachine } from "@xstate/react";
import * as actions from "../../+xstate/actions/ongoing-session";
import { ongoingSessionMachine } from "../../+xstate/machines/ongoing-session";
import {
  useInfiniteTimer,
  INFINITE_TIMER_TICK,
} from "../../hooks/useInfiniteTimer";

import { getTeamName } from "../../utils/get-team-name";
import { getCurrentLevel } from "../../utils/activities";
import { getEntryId } from "../../utils";

import {
  StandardSessionActivity,
  standardSessionActivityList,
} from "../../apollo-graphql/types/enums/standard-session-activity";
import { ApolloContext } from "../../contexts/Apollo";
import { ActivityType } from "../../types/contentful/workshop/activity-type";
import { Session } from "../../apollo-graphql/types/session";
import { WorkshopClock } from "../../helpers/workshop-clock";
import { SPLIT_KEY_REG_EX } from "../../constants/global";

import InfoBox from "../InfoBox/InfoBox";
import Countdown from "../Shared/Countdown/Countdown";

import styles from "./SlotActiveSessionList.module.css";
import Loader from "../Shared/Loader/Loader";

const SlotActiveSessionList = (
  props: PropsWithChildren<{
    slotId: string;
    workspaceContactEmail?: string;
    workshopActivities: ActivityType[];
    sessions: Session[];
    workshopDuration: number;
  }>
) => {
  const {
    slotId,
    workspaceContactEmail,
    workshopActivities,
    sessions,
    workshopDuration,
  } = props;

  const { client, serverTimeEventTarget } = useContext(ApolloContext);
  const [sessionTimers, setSessionTimers] = useState<
    { time: string; progress: number }[]
  >([]);
  const navigate = useNavigate();

  const [state, send] = useMachine(ongoingSessionMachine, {
    input: { client },
  });

  const ongoingSessions = useMemo(() => {
    if (!state.context.ongoingSessions) return null;
    return state.context.ongoingSessions
      .map((os) => ({
        ...os,
        sessionKey:
          sessions.find(({ id }) => os.key === id)?.session_key || null,
      }))
      ?.sort((a, b) =>
        a.value.context.currentActiveProfiles.length <
        b.value.context.currentActiveProfiles.length
          ? -1
          : 1
      )
      ?.sort((a, b) => {
        const aLevel = getCurrentLevel(a.value);
        const bLevel = getCurrentLevel(b.value);
        return aLevel < bLevel ? -1 : 1;
      });
  }, [sessions, state.context.ongoingSessions]);

  const isFetchingOngoingSessions = useMemo(
    () => ongoingSessions === null,
    [ongoingSessions]
  );

  const activitiesCount = useMemo(
    () =>
      workshopActivities.filter(
        (activity) =>
          !standardSessionActivityList.includes(
            getEntryId(activity) as StandardSessionActivity
          )
      ).length,
    [workshopActivities]
  );

  const tableBodyContent = useMemo(() => {
    if (!ongoingSessions) return null;

    const noData =
      ongoingSessions.length === 0 ||
      !ongoingSessions[0].value ||
      ongoingSessions[0].value.context === null;
    if (noData) {
      return (
        <tr
          className={styles.sessionListItem}
          data-test-locator="waiting-room-slot-active-session-list-no-data-row"
        >
          <td colSpan={5} style={{ textAlign: "center" }}>
            No data.
          </td>
        </tr>
      );
    }

    return ongoingSessions.map(({ key, value }, index) => {
      const currentLevel = getCurrentLevel(value);
      const activePlayersCount = value.context.currentActiveProfiles.length;
      const { maximumWorkshopParticipants } = value.context;
      const currentSessionTimer = sessionTimers[index];

      const isCompleted = activitiesCount < currentLevel;

      const joinLinkSessionData = key.replace(SPLIT_KEY_REG_EX, "$2/$1");

      const joinLink = joinLinkSessionData
        ? `/session/instance/${joinLinkSessionData}`
        : null;

      const canJoinButtonDisabled = !joinLink || isCompleted;

      const teamName = getTeamName(
        value.context.activityResult,
        `Room ${key?.split(":")[0]}`
      );

      return (
        <tr
          className={styles.sessionListItem}
          key={key}
          data-test-locator="waiting-room-slot-active-session-list-data-row"
        >
          <td className="text bold">{teamName}</td>
          <td className="text">
            <div
              className={cn(
                "players-count",
                activePlayersCount + 1 === maximumWorkshopParticipants &&
                  "almost-full",
                activePlayersCount === maximumWorkshopParticipants && "full"
              )}
              data-test-locator="waiting-room-slot-active-session-list-data-row-player-count"
            >
              <i className="icon fa fa-user"></i>
              <span
                className={cn("text", "tiny", "bold", [styles.playersCount])}
              >
                {activePlayersCount >= maximumWorkshopParticipants
                  ? "Full"
                  : activePlayersCount}{" "}
              </span>
            </div>
          </td>
          <td
            className="text time-left-container"
            data-test-locator="waiting-room-slot-active-session-list-data-row-countdown-container"
          >
            {currentSessionTimer && (
              <Countdown
                timeValue={currentSessionTimer.time}
                progress={currentSessionTimer.progress}
                timeIsRunningUp={false}
              />
            )}
          </td>
          <td
            className="text"
            data-test-locator="waiting-room-slot-active-session-list-data-row-current-activity"
          >
            {isCompleted ? (
              "Completed"
            ) : (
              <>
                {currentLevel}{" "}
                <span className="faded">out of {activitiesCount}</span>
              </>
            )}
          </td>
          <td className="text action">
            <button
              className={cn("btn", "small")}
              disabled={canJoinButtonDisabled}
              onClick={() => navigate(joinLink!)}
              data-test-locator="waiting-room-slot-active-session-list-join-button"
            >
              <i className="fa-regular fa-play"></i>
              <span className="">Join</span>
            </button>
          </td>
        </tr>
      );
    });
  }, [activitiesCount, navigate, ongoingSessions, sessionTimers]);

  const tickEventTarget = useInfiniteTimer({
    isReadyToInitialize: !isFetchingOngoingSessions,
    serverTimeEventTarget,
  });

  const sessionEndTimestampsString = useMemo(
    () =>
      ongoingSessions
        ?.map(
          (os) =>
            (os.value.context.startTimestamp || Infinity) +
            workshopDuration * 60
        )
        .join(","),
    [ongoingSessions, workshopDuration]
  );

  const tickHandler = useCallback(
    (event: Event) => {
      if (!ongoingSessions) return;

      const { currentServerTime, dispose } = (event as CustomEvent).detail as {
        currentServerTime: number;
        dispose: () => void;
      };

      const results: {
        time: string;
        progress: number;
      }[] = ongoingSessions.map((os) => {
        const startTimestamp = os.value.context.startTimestamp;
        if (startTimestamp === null) {
          const totalSeconds = workshopDuration * 60;
          const remainingMinutes = Math.floor(totalSeconds / 60);
          const remainingSeconds = totalSeconds % 60;
          return {
            time: WorkshopClock.prototype.formatTime(
              remainingMinutes,
              remainingSeconds
            ),
            progress: 100,
          };
        }

        const endTimestamp = startTimestamp + workshopDuration * 60;
        const secondsRemaining = endTimestamp - currentServerTime;

        const currentLevel = getCurrentLevel(os.value);
        const isCompleted = activitiesCount < currentLevel;

        if (secondsRemaining < 0 || isCompleted) {
          return {
            time: WorkshopClock.prototype.formatTime(0, 0),
            progress: 0,
          };
        }

        const time =
          WorkshopClock.prototype.parseTickCountToString(secondsRemaining);
        return {
          time,
          progress: (secondsRemaining / (workshopDuration * 60)) * 100,
        };
      });

      setSessionTimers(results);

      const sessionEndTimestamps = sessionEndTimestampsString
        ?.split(",")
        .map(Number);

      const allSessionsAreFinished = sessionEndTimestamps?.every(
        (t) => t <= currentServerTime
      );
      if (allSessionsAreFinished) dispose();
    },
    [
      activitiesCount,
      ongoingSessions,
      sessionEndTimestampsString,
      workshopDuration,
    ]
  );

  useEffect(() => {
    tickEventTarget.addEventListener(INFINITE_TIMER_TICK, tickHandler);
    return () => {
      tickEventTarget.removeEventListener(INFINITE_TIMER_TICK, tickHandler);
    };
  }, [tickEventTarget, tickHandler]);

  useEffect(() => {
    send(
      actions.ongoingSessionsSubscribe({
        slot_ids: [slotId],
      })
    );
  }, [send, slotId]);

  return (
    <div className={styles.container}>
      <InfoBox
        title="Room is already at maximum capacity"
        description="You can’t join the workshop, please choose another session below."
        isDismissible
        className={styles.infoBox}
        variant="danger"
        testLocatorName="waiting-room-slot-active-session-list-info"
      />
      <div className={styles.sessionListContainer}>
        <div className={styles.headerContainer}>
          <h1 data-test-locator="waiting-room-slot-active-session-info-footer">
            <p>You are late for your conversation!</p>
            <p>Preferably join the session on top of this list:</p>
          </h1>
        </div>
        <div className={styles.content}>
          {ongoingSessions === null ? (
            <Loader className={styles.loaderContainer} />
          ) : (
            <table className={styles.sessionListTable}>
              <thead>
                <tr>
                  <th className="text bold">Session</th>
                  <th className="text bold">Players</th>
                  <th className="text bold">Time Left</th>
                  <th className="text bold">Current Level</th>
                </tr>
              </thead>
              <tbody>{tableBodyContent}</tbody>
            </table>
          )}
        </div>
        <div className={styles.footer}>
          <p className={cn("text", [styles.footerQuestion])}>
            Do you want to get another time slot?
          </p>
          <a href={`mailto:${workspaceContactEmail}`} className="text email">
            {workspaceContactEmail}
          </a>
        </div>
      </div>
    </div>
  );
};

export default memo(SlotActiveSessionList);
