import {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";

import cn from "classnames";
import * as Form from "@radix-ui/react-form";

import withRouteConfig from "../../hocs/withRouteConfig";

import { parseServerErrors } from "../../utils/parse-server-errors";
import { GlobalContext } from "../../contexts/Global";
import { AuthState, resendInvitationReset } from "../../+xstate/machines/auth";
import {
  RefreshTokenErrors,
  ResendInvitationError,
} from "../../types/enums/errors";
import { ILogin } from "../../apollo-graphql/types/login";
import { mappedErrorMessages } from "../../constants/mapped-error-messages";

import Copyright from "../../components/Copyright/Copyright";
import Password from "../../components/Shared/Password/Password";
import Toast, { ToastType } from "../../components/Shared/Toast/Toast";
import LoadingButton from "../../components/Shared/Buttons/LoadingButton/LoadingButton";

import styles from "./Login.module.css";
import Email from "../../components/Shared/Email/Email";
import { FetchState } from "../../+xstate/machines/fetch-factory";

const termOfServiceLink =
  "https://assets-global.website-files.com/65673b691b84d3435b29977d/65e08a4474f019f2a2a699d3_TERMS%20OF%20SERVICE.pdf";
const privacyPolicyLink = "https://www.ahaplay.com/privacy-policy";

const {
  [RefreshTokenErrors.TOKEN_NOT_FOUND]: _,
  ...mappedErrorMessagesForLogin
} = mappedErrorMessages;

function Login() {
  const {
    auth: {
      login,
      resendInvitation,
      loginMachineState,
      resendInvitationMachineState,
      resendInvitationMachineSend,
      context: { unauthenticatedUserEmail },
      matches,
    },
  } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();

  const isAuthenticating = matches(AuthState.Authenticating);
  const isAuthenticated = matches(AuthState.Authenticated);
  const isGettingInvitation = matches(AuthState.GettingInvitation);

  const loginError = useMemo(() => {
    return parseServerErrors(loginMachineState.context.error);
  }, [loginMachineState.context.error]);
  const resendInvitationError = useMemo(() => {
    return parseServerErrors(resendInvitationMachineState.context.error);
  }, [resendInvitationMachineState.context.error]);

  const isResendInvitationLoading =
    resendInvitationMachineState.value === FetchState.Fetching;

  const email = useMemo(
    () => unauthenticatedUserEmail || "",
    [unauthenticatedUserEmail]
  );
  const redirectUrl = useMemo(
    () => searchParams.get("redirectUrl") || "/",
    [searchParams]
  );

  const toastData = useMemo(() => {
    let type = ToastType.ERROR;
    let description = null;
    let title = "";

    if (loginError && !isAuthenticating) {
      description =
        mappedErrorMessagesForLogin[
          loginError as keyof typeof mappedErrorMessagesForLogin
        ];
      title = "Login";
      type = ToastType.ERROR;
    }

    if (!isGettingInvitation) {
      if (resendInvitationError) {
        title = "Get invitation";
        description =
          mappedErrorMessagesForLogin[
            resendInvitationError as keyof typeof mappedErrorMessagesForLogin
          ];

        if (
          [
            ResendInvitationError.PROFILE_NOT_FOUND,
            ResendInvitationError.PROFILE_ALREADY_REGISTERED,
          ].includes(resendInvitationError as ResendInvitationError)
        ) {
          type = ToastType.SUCCESS;
        }
      } else if (resendInvitationMachineState.context.data?.success) {
        title = "Get invitation";
        description =
          mappedErrorMessagesForLogin[ResendInvitationError.PROFILE_NOT_FOUND];
        type = ToastType.SUCCESS;
      }
    }

    return { title, description, type };
  }, [
    loginError,
    isAuthenticating,
    isGettingInvitation,
    resendInvitationError,
    resendInvitationMachineState.context.data?.success,
  ]);

  const loginHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const payload = Object.fromEntries(
        new FormData(event.currentTarget)
      ) as unknown as ILogin;

      const variables: ILogin = {
        email: payload.email.trim(),
        password: payload.password.trim(),
      };

      login({ variables });
    },
    [login]
  );

  const needsRecaptchaVerification = useMemo(() => {
    return (
      resendInvitationError === ResendInvitationError.RECAPTCHA_SCORE_TOO_LOW
    );
  }, [resendInvitationError]);

  const emailRef = useRef<string>("");
  const resendInviteHandler = useCallback(
    (email: string, notARobotToken?: string) => {
      if (!notARobotToken) {
        grecaptcha.enterprise.ready(async () => {
          const token = await grecaptcha.enterprise.execute(
            "6LcsSGIqAAAAANiPnkU-_odBT_PmszqQB2jXoeEH",
            { action: "LOGIN" }
          );

          const variables = {
            email,
            grecaptchaTokens: [token],
          };

          emailRef.current = email;
          resendInvitation({ variables });
        });
        return;
      }

      const variables = {
        email,
        grecaptchaTokens: ["", notARobotToken],
      };
      resendInvitation({ variables });
    },
    [resendInvitation]
  );

  const grecaptchaWidgetId = useRef<number | null>(null);
  useEffect(() => {
    if (!needsRecaptchaVerification) return;

    if (grecaptchaWidgetId.current !== null) {
      grecaptcha.reset(grecaptchaWidgetId.current);
    } else {
      grecaptchaWidgetId.current = grecaptcha.render("recaptcha", {
        sitekey: "6LfpJGMqAAAAAOxS-ypQ5VeVG4riqOXUypPX70iv",
        callback: (token: string) => {
          resendInviteHandler(emailRef.current, token);
        },
        "expired-callback": () => {
          resendInvitationMachineSend(resendInvitationReset);
        },
      });
    }
  }, [
    resendInvitationMachineSend,
    needsRecaptchaVerification,
    resendInviteHandler,
  ]);

  const resendInviteClickHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const payload = Object.fromEntries(
        new FormData(event.currentTarget)
      ) as unknown as ILogin;
      resendInviteHandler(payload.email.trim());
    },
    [resendInviteHandler]
  );

  const domain = useMemo(() => {
    const { href } = window.location;
    const rawDomain = href.split("//")[1].split(".")[0];
    if (rawDomain.includes("localhost")) return "AhaPlay";
    return `${rawDomain[0].toUpperCase()}${rawDomain.slice(1)}`;
  }, []);

  return isAuthenticated ? (
    <Navigate to={redirectUrl} />
  ) : (
    <>
      <div className={styles.loginContainer}>
        <h2 className={cn(styles.pageTitle, "thin")}>Login to AhaPlay</h2>
        <div className={styles.loginSections}>
          <div className={styles.loginSection}>
            <div className={cn("FormContainer", styles.formContainer)}>
              <Form.Root
                className={cn("Form", styles.form)}
                onSubmit={loginHandler}
              >
                <div className="FormFieldsContainer">
                  <Email
                    label="Work email"
                    defaultValue={email}
                    placeholder="Work email address"
                    testLocatorName="login-email-filed"
                  />

                  <Password
                    name="password"
                    label="Password"
                    placeholder="Password"
                    testLocatorName="login-password-filed"
                  />
                </div>
                <LoadingButton
                  type="submit"
                  className="btn large"
                  disabled={isAuthenticating}
                  isLoading={isAuthenticating}
                  testLocatorName="login-button"
                >
                  Continue with email
                </LoadingButton>
                <Link
                  className={cn("text", "secondary", styles.forgotPassword)}
                  to={"/reset-password"}
                  data-test-locator="forgot-password-button"
                >
                  Forgot your password?
                </Link>
              </Form.Root>
            </div>
          </div>

          <div className={styles.loginSection}>
            <div className={cn("FormContainer", styles.formContainer)}>
              <Form.Root
                className={cn("Form", styles.form)}
                onSubmit={resendInviteClickHandler}
              >
                <p className={cn("text", styles.invitationInfo)}>
                  <b>Didn't complete your profile yet?</b> Add your email to
                  receive an invitation to join {domain}'s workspace.
                </p>
                <div className="FormFieldsContainer">
                  <Email
                    label="Work email"
                    placeholder="Work email address"
                    testLocatorName="resend-invite-email-input"
                  />
                </div>
                {!needsRecaptchaVerification && (
                  <LoadingButton
                    type="submit"
                    className="btn large"
                    disabled={isResendInvitationLoading || isAuthenticating}
                    isLoading={isResendInvitationLoading}
                    testLocatorName="resend-invite-button"
                  >
                    Get invitation
                  </LoadingButton>
                )}
                <div
                  id="recaptcha"
                  style={{
                    display: needsRecaptchaVerification ? "initial" : "none",
                  }}
                ></div>
              </Form.Root>
            </div>
          </div>
        </div>
        <div className={cn("text", "small", styles.footerMessage)}>
          By continuing, you agree to accept our<br></br>
          <a
            className="underline"
            target="_blank"
            rel="noreferrer"
            href={termOfServiceLink}
          >
            Terms of Service
          </a>{" "}
          &{" "}
          <a
            className="underline"
            target="_blank"
            rel="noreferrer"
            href={privacyPolicyLink}
          >
            Privacy Policy
          </a>
          .
        </div>
        <Copyright />
      </div>

      <Toast
        title={toastData.title}
        description={toastData.description}
        type={toastData.type}
      />
    </>
  );
}

export default withRouteConfig(Login);
