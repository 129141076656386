import { ConnectionStrength } from "../../types/enums/connection-strength";
import { createAction, props } from "../utils";

export const reset = createAction("[SOCKET] reset");
export const opened = createAction("[SOCKET] opened");
export const closed = createAction("[SOCKET] closed", props<{ event: any }>());
export const error = createAction("[SOCKET] error", props<{ event: any }>());
export const connected = createAction("[SOCKET] connected");
export const connecting = createAction("[SOCKET] connecting");
export const retry = createAction("[SOCKET] retry");
export const pending = createAction("[SOCKET] pending");
export const setConnectionStrength = createAction(
  "[SOCKET] set connection strength",
  props<{ connectionStrength: ConnectionStrength }>()
);
