import {
  Fragment,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import { startOfDay, endOfDay, format } from "date-fns";
import cn from "classnames";

import { Slot } from "../../apollo-graphql/types/slot";
import { formatSlotScheduleDate } from "../../utils/format-schedule-date";
import { InvitationStatus } from "../../types/enums/invitation-status";

import NoSlotsFound from "../NoSlotsFound/NoSlotsFound";
import DatePicker from "../Shared/DatePicker/DatePicker";

import styles from "./Reschedule.module.css";

const Reschedule = (
  props: PropsWithChildren<{
    invitationId: string;
    workshopTitle: string;
    profileId: string;
    scheduledSlots: Slot[] | null;
    hideGoBackButton: boolean;
    rescheduleHandler: (invitationId: string, slot: Slot) => void;
    navigateToWorkshop: () => void;
  }>
) => {
  const {
    profileId,
    invitationId,
    workshopTitle,
    scheduledSlots,
    hideGoBackButton,
    rescheduleHandler,
    navigateToWorkshop,
  } = props;
  const [filterDate, setFilterDate] = useState<Date | null>(null);

  const onFilterDateChange = useCallback((date: Date | null) => {
    setFilterDate(date);
  }, []);

  const todayTimestamp = useMemo(() => new Date().getTime(), []);

  const slots = useMemo(() => {
    if (!filterDate) return scheduledSlots;
    const startTimeStamp = startOfDay(filterDate).getTime() / 1000;
    const endTimeStamp = endOfDay(filterDate).getTime() / 1000;

    return scheduledSlots?.filter((s) => {
      const slotStartTimestamp = new Date(s.schedule_date).getTime();

      return (
        startTimeStamp <= slotStartTimestamp &&
        endTimeStamp >= slotStartTimestamp
      );
    });
  }, [filterDate, scheduledSlots]);

  const slotsContent = useMemo(
    () =>
      slots?.length ? (
        slots.map((slot) => {
          const isEnrolled = slot.invitations.find(
            (i) =>
              i.profile.id === profileId &&
              i.status !== InvitationStatus.AUTO_GENERATED
          );
          const workshopInfoText =
            new Date(slot.schedule_date).getTime() <= todayTimestamp / 1000
              ? "Starts on"
              : "The conversation will start soon";

          return (
            <Fragment key={slot.id}>
              <div className="line" />
              <div className="workshop-content">
                <div className="workshop-info">
                  <p data-test-locator="waiting-room-reschedule-workshop-info-text">
                    {workshopInfoText}
                  </p>
                  <h3
                    className="bold"
                    data-test-locator="waiting-room-reschedule-workshop-schedule-date"
                  >
                    {formatSlotScheduleDate(slot.schedule_date)}
                  </h3>
                </div>
                <button
                  className={cn(
                    "workshop-button",
                    isEnrolled ? "enrolled" : "not-enrolled"
                  )}
                  disabled={!!isEnrolled}
                  data-test-locator="waiting-room-reschedule-workshop-reschedule-button"
                  onClick={
                    isEnrolled
                      ? () => {}
                      : () => rescheduleHandler(invitationId, slot)
                  }
                >
                  <span className="icon"></span>
                  {isEnrolled ? "Enrolled" : "Select"}
                </button>
              </div>
            </Fragment>
          );
        })
      ) : (
        <>
          <div className="line" />
          <div
            className={styles.noConversationsFound}
            data-test-locator="waiting-room-reschedule-workshop-no-conversations-found"
          >
            No Conversations found.
          </div>
        </>
      ),
    [invitationId, profileId, rescheduleHandler, slots, todayTimestamp]
  );

  const noRescheduleWorkshopContent = useMemo(
    () => (
      <div
        className={styles.notFoundContainer}
        data-test-locator="waiting-room-reschedule-workshop-no-slots-found-container"
      >
        <NoSlotsFound type="upcoming" goBackClickHandler={navigateToWorkshop} />
      </div>
    ),
    [navigateToWorkshop]
  );

  const rescheduleWorkshopContent = useMemo(
    () => (
      <>
        <div>
          <h6
            className="text"
            data-test-locator="waiting-room-reschedule-workshop-choose-available-slots"
          >
            Choose an available time slots for team conversation
          </h6>
          <h1>{workshopTitle}</h1>
        </div>
        <div className={styles.rescheduleContent}>
          <div>
            <div
              className={styles.inputContainer}
              data-test-locator="waiting-room-reschedule-workshop-date-picker-container"
            >
              <DatePicker
                value={
                  filterDate
                    ? format(filterDate as Date, "yyyy-MM-dd")
                    : undefined
                }
                onChange={onFilterDateChange}
                placeholderText="Filter by date"
                classNames={styles.datePicker}
                isClearable
              />
            </div>
          </div>
          <div
            className="workshops-container"
            data-test-locator="waiting-room-reschedule-slots-content-container"
          >
            {slotsContent}
          </div>
        </div>
        {!hideGoBackButton && (
          <button
            className={styles.workshopBtn}
            onClick={navigateToWorkshop}
            data-test-locator="waiting-room-reschedule-go-back-button"
          >
            Go Back
          </button>
        )}
      </>
    ),
    [
      filterDate,
      navigateToWorkshop,
      onFilterDateChange,
      slotsContent,
      workshopTitle,
      hideGoBackButton,
    ]
  );

  const content = useMemo(() => {
    return scheduledSlots?.length
      ? rescheduleWorkshopContent
      : noRescheduleWorkshopContent;
  }, [
    noRescheduleWorkshopContent,
    rescheduleWorkshopContent,
    scheduledSlots?.length,
  ]);

  return content;
};

export default memo(Reschedule);
