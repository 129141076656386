import {
  createMachine,
  assign,
  sendTo,
  spawnChild,
  raise,
  fromPromise,
} from "xstate";
import { AppApolloClient } from "../../contexts/Apollo";
import * as actions from "../actions/team-members";
import * as workspaceTagsActions from "../../apollo-graphql/actions/workspace-tags";
import { getProfile, getProfiles } from "../../apollo-graphql/queries/profile";
import { Pagination } from "../../types/pagination";
import { fetchMachineFactory } from "./fetch-factory";
import { inviteProfile } from "../../apollo-graphql/mutations/invite-profile";
import { Profile } from "../../apollo-graphql/types/profile";
import { updateProfile } from "../../apollo-graphql/mutations/update-profile";
import { deleteProfile } from "../../apollo-graphql/mutations/delete-profile";
import {
  deleteImageFromS3,
  getPreSignedUrl,
  uploadImageToS3,
} from "../../fetch/image";
import { getUnixTime } from "date-fns/getUnixTime";
import {
  getWorkspaceTagsMachine,
  getWorkspaceTagsTrigger,
} from "../../apollo-graphql/machines/workspace-tags";
import { WorkspaceTagsActions } from "../../apollo-graphql/types/action-types/workspace-tags";
import { bulkInvite } from "../../apollo-graphql/mutations";
import {
  ProfileWorkspaceAccess,
  ProfileWorkspaceStatus,
} from "../../apollo-graphql/types/enums";
import { parseXLSXInvites } from "../../utils/xlsx-utils";
import { infiniteScrollMachineFactory } from "./infinite-scroll-factory";

export enum TeamMembersState {
  List = "list",
  InviteDialog = "inviteDialog",
  EditDialog = "editDialog",
  DeleteDialog = "deleteDialog",
  BulkInvite = "bulkInvite",
}

export enum TeamMemberBulkInviteState {
  Initial = "initial",
  Parsing = "parsing",
  Ready = "ready",
}

interface TeamMembersMachineContext {
  client: AppApolloClient;
  workspaceId: string;
  error: string | null;
  selectedTeamMember: Profile | null;
  teamMemberToBeDeleted: Profile | null;
  selectedTeamMemberImageData: {
    presignedUrl: string | null;
    lastUpdatedTimeStamp: number | null;
  };
  invites:
    | {
        name: string;
        email: string;
        jobTitle: string;
        access: ProfileWorkspaceAccess;
        status: ProfileWorkspaceStatus;
      }[]
    | null;
}

type TeamMemberActionCreators = typeof actions;
type TeamMemberActionCreatorKeys = keyof TeamMemberActionCreators;
type TeamMemberActions = ReturnType<
  TeamMemberActionCreators[TeamMemberActionCreatorKeys]
>;

type TeamMembersMachineTypes = {
  context: TeamMembersMachineContext;
  events:
    | TeamMemberActions
    | WorkspaceTagsActions
    | ReturnType<typeof getTeamMembersSuccess>
    | ReturnType<typeof getTeamMembersFailure>
    | ReturnType<typeof inviteTeamMemberSuccess>
    | ReturnType<typeof inviteTeamMemberFailure>
    | ReturnType<typeof getTeamMemberSuccess>
    | ReturnType<typeof getTeamMemberFailure>
    | ReturnType<typeof editTeamMemberSuccess>
    | ReturnType<typeof editTeamMemberFailure>
    | ReturnType<typeof deleteTeamMemberSuccess>
    | ReturnType<typeof deleteTeamMemberFailure>
    | ReturnType<typeof getPreSignedTeamMemberUrlSuccess>
    | ReturnType<typeof getPreSignedTeamMemberUrlFailure>
    | ReturnType<typeof uploadTeamMemberImageSuccess>
    | ReturnType<typeof uploadTeamMemberImageFailure>
    | ReturnType<typeof deleteTeamMemberImageSuccess>
    | ReturnType<typeof deleteTeamMemberImageFailure>
    | ReturnType<typeof getTeamMemberSuccess>
    | ReturnType<typeof getTeamMemberSuccess>
    | ReturnType<typeof bulkInviteSuccess>
    | ReturnType<typeof bulkInviteFailure>
    | ReturnType<typeof setLoadTeamMembersCurrentRenderedRange>
    | ReturnType<typeof retryLoadTeamMembers>;
};

export const {
  machine: getTeamMembersMachine,
  success: getTeamMembersSuccess,
  failure: getTeamMembersFailure,
  trigger: getTeamMembersTrigger,
  retry: getTeamMembersRetry,
  reset: getTeamMembersReset,
  cancel: getTeamMembersCancel,
  done: getTeamMembersDone,
} = fetchMachineFactory({
  id: "getTeamMembers",
  invokeFn: ({
    client,
    ...data
  }: {
    client: AppApolloClient;
    workspaceId: string;
    emails?: string[];
    ids?: string[];
    pagination?: Pagination;
    query?: string;
  }) => {
    return getProfiles(client, data);
  },
});

const {
  machine: getTeamMemberMachine,
  success: getTeamMemberSuccess,
  failure: getTeamMemberFailure,
  trigger: getTeamMemberTrigger,
} = fetchMachineFactory({
  id: "getTeamMember",
  invokeFn: ({ client, id }: { client: AppApolloClient; id: string }) => {
    return getProfile(client, { id }).then((data) => ({
      profile: data,
    }));
  },
});

export const {
  machine: inviteTeamMemberMachine,
  success: inviteTeamMemberSuccess,
  failure: inviteTeamMemberFailure,
  trigger: inviteTeamMemberTrigger,
  reset: inviteTeamMemberReset,
} = fetchMachineFactory({
  id: "inviteTeamMember",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<TeamMemberActionCreators["inviteSend"]>["payload"];
  }) => {
    return inviteProfile(client, payload.variables);
  },
});

export const {
  machine: editTeamMemberMachine,
  success: editTeamMemberSuccess,
  failure: editTeamMemberFailure,
  trigger: editTeamMemberTrigger,
} = fetchMachineFactory({
  id: "editTeamMember",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<TeamMemberActionCreators["editSubmit"]>["payload"];
  }) => {
    return updateProfile(client, payload.variables);
  },
});

export const {
  machine: deleteTeamMemberMachine,
  success: deleteTeamMemberSuccess,
  failure: deleteTeamMemberFailure,
  trigger: deleteTeamMemberTrigger,
} = fetchMachineFactory({
  id: "deleteTeamMember",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<TeamMemberActionCreators["deleteSubmit"]>["payload"];
  }) => {
    return deleteProfile(client, payload.variables);
  },
});

export const {
  machine: getPreSignedTeamMemberUrlMachine,
  success: getPreSignedTeamMemberUrlSuccess,
  failure: getPreSignedTeamMemberUrlFailure,
  trigger: getPreSignedTeamMemberUrlTrigger,
} = fetchMachineFactory({
  id: "getPreSignedTeamMemberUrl",
  invokeFn: ({ token, key }: { token: string; key: string }) => {
    return getPreSignedUrl(token, key);
  },
});

export const {
  machine: uploadTeamMemberImageMachine,
  success: uploadTeamMemberImageSuccess,
  failure: uploadTeamMemberImageFailure,
  trigger: uploadTeamMemberImageTrigger,
} = fetchMachineFactory({
  id: "uploadTeamMemberImage",
  invokeFn: ({ url, body }: { url: string; body: File | ReadableStream }) => {
    return uploadImageToS3(url, body);
  },
});

export const {
  machine: deleteTeamMemberImageMachine,
  success: deleteTeamMemberImageSuccess,
  failure: deleteTeamMemberImageFailure,
  trigger: deleteTeamMemberImageTrigger,
} = fetchMachineFactory({
  id: "deleteTeamMemberImage",
  invokeFn: ({ key, token }: { key: string; token: string }) => {
    return deleteImageFromS3(key, token);
  },
});

export const {
  machine: bulkInviteMachine,
  trigger: bulkInviteTrigger,
  reset: bulkInviteReset,
  success: bulkInviteSuccess,
  failure: bulkInviteFailure,
} = fetchMachineFactory({
  id: "bulkInvite",
  invokeFn: ({
    client,
    invites,
    workspaceId,
  }: {
    workspaceId?: string;
    invites: {
      name: string;
      email: string;
      jobTitle: string;
      access: ProfileWorkspaceAccess;
      status: ProfileWorkspaceStatus;
    }[];
    client: AppApolloClient;
  }) => {
    return bulkInvite(client, { invites, workspaceId });
  },
});

export const {
  machine: teamMembersInfiniteScrollMachine,
  loadAction: loadTeamMembers,
  retryAction: retryLoadTeamMembers,
  setCurrentRenderedRange: setLoadTeamMembersCurrentRenderedRange,
} = infiniteScrollMachineFactory({
  id: "team-members-infinite-scroll",
  invokeFn: ({
    client,
    workspaceId,
    page,
    pageSize,
    query,
  }: {
    client: AppApolloClient;
    workspaceId: string;
    pageSize: number;
    page: number;
    query?: string;
  }) => {
    const pagination = {
      offset: ((page || 1) - 1) * pageSize,
      limit: pageSize,
    };
    return getProfiles(client, {
      workspaceId,
      pagination,
      query,
    });
  },
});

export const teamMembersMachine = createMachine({
  types: {} as TeamMembersMachineTypes,
  id: "team-members",
  initial: TeamMembersState.List,
  context: ({ input }): TeamMembersMachineContext => {
    const machineInput = input as
      | {
          client?: AppApolloClient;
          workspaceId: string;
        }
      | undefined;

    if (!machineInput?.client)
      throw new Error("Apollo client must be provided!");

    return {
      client: machineInput.client,
      workspaceId: machineInput.workspaceId,
      error: null,
      selectedTeamMember: null,
      teamMemberToBeDeleted: null,
      selectedTeamMemberImageData: {
        presignedUrl: null,
        lastUpdatedTimeStamp: null,
      },
      invites: null,
    };
  },
  entry: [
    spawnChild(getTeamMemberMachine, {
      id: getTeamMemberMachine.id,
      systemId: getTeamMemberMachine.id,
    }),
    spawnChild(editTeamMemberMachine, {
      id: editTeamMemberMachine.id,
      systemId: editTeamMemberMachine.id,
    }),
    spawnChild(deleteTeamMemberMachine, {
      id: deleteTeamMemberMachine.id,
      systemId: deleteTeamMemberMachine.id,
    }),
    spawnChild(inviteTeamMemberMachine, {
      id: inviteTeamMemberMachine.id,
      systemId: inviteTeamMemberMachine.id,
    }),
    spawnChild(getPreSignedTeamMemberUrlMachine, {
      id: getPreSignedTeamMemberUrlMachine.id,
      systemId: getPreSignedTeamMemberUrlMachine.id,
    }),
    spawnChild(uploadTeamMemberImageMachine, {
      id: uploadTeamMemberImageMachine.id,
      systemId: uploadTeamMemberImageMachine.id,
    }),
    spawnChild(deleteTeamMemberImageMachine, {
      id: deleteTeamMemberImageMachine.id,
      systemId: deleteTeamMemberImageMachine.id,
    }),
    spawnChild(getWorkspaceTagsMachine, {
      id: getWorkspaceTagsMachine.id,
      systemId: getWorkspaceTagsMachine.id,
    }),
    spawnChild(bulkInviteMachine, {
      id: bulkInviteMachine.id,
      systemId: bulkInviteMachine.id,
    }),
    spawnChild(teamMembersInfiniteScrollMachine, {
      id: teamMembersInfiniteScrollMachine.id,
      systemId: teamMembersInfiniteScrollMachine.id,
    }),
  ],
  states: {
    [TeamMembersState.List]: {
      on: {
        [actions.fetchTeamMembers.type]: {
          actions: [
            sendTo(
              teamMembersInfiniteScrollMachine.id,
              ({ event, context }) => {
                const { client } = context;
                const { workspaceId, filters } = event.payload;
                return loadTeamMembers({
                  client,
                  workspaceId,
                  page: filters.currentPage!,
                  pageSize: filters.pageSize!,
                  query: filters.query,
                });
              }
            ),
          ],
        },
        [actions.editOpen.type]: {
          target: TeamMembersState.EditDialog,
        },
        [actions.deleteOpen.type]: {
          target: TeamMembersState.DeleteDialog,
          actions: [
            assign({
              teamMemberToBeDeleted: ({ event }) => event.payload.teamMember,
            }),
          ],
        },
        [actions.inviteOpen.type]: {
          target: TeamMembersState.InviteDialog,
        },
        [workspaceTagsActions.getWorkspaceTags.type]: {
          actions: [
            sendTo(getWorkspaceTagsMachine.id, ({ event, context }) => {
              return getWorkspaceTagsTrigger({
                client: context.client,
                workspaceId: event.payload.workspaceId,
              });
            }),
          ],
        },
        [actions.bulkInviteDialogOpen.type]: {
          target: TeamMembersState.BulkInvite,
        },
      },
    },
    [TeamMembersState.InviteDialog]: {
      on: {
        [actions.inviteSend.type]: {
          actions: [
            sendTo(inviteTeamMemberMachine.id, ({ event, context }) => {
              event = event as ReturnType<
                TeamMemberActionCreators["inviteSend"]
              >;

              return inviteTeamMemberTrigger({
                client: context.client,
                payload: event.payload,
                force: true,
              });
            }),
          ],
        },
        [inviteTeamMemberSuccess.type]: [
          {
            guard: ({ event }) =>
              !!event.payload.input.payload.variables.newTags?.length,
            actions: [
              assign({ error: null }),
              sendTo(getWorkspaceTagsMachine.id, ({ context, event }) => {
                return getWorkspaceTagsTrigger({
                  client: context.client,
                  workspaceId:
                    event.payload.input.payload.variables.workspaceId,
                });
              }),
              raise(() => actions.inviteClose()),
            ],
          },
          {
            guard: ({ event }) =>
              (event.payload.input.payload.variables.newTags?.length || 0) ===
              0,
            actions: [
              assign({ error: null }),
              raise(() => actions.inviteClose()),
            ],
          },
        ],
        [inviteTeamMemberFailure.type]: {
          actions: [
            assign({
              error: ({ event }) => `${event.payload.error.message}`,
            }),
          ],
        },
        [actions.inviteClose.type]: {
          target: TeamMembersState.List,
          actions: [
            ({ self }) => self.send(retryLoadTeamMembers()),
            sendTo(inviteTeamMemberMachine.id, () => inviteTeamMemberReset()),
            assign({
              error: null,
            }),
          ],
        },
      },
    },
    [TeamMembersState.EditDialog]: {
      entry: [
        sendTo(getTeamMemberMachine.id, ({ context, event }) => {
          event = event as ReturnType<TeamMemberActionCreators["editOpen"]>;
          return getTeamMemberTrigger({
            client: context.client,
            id: event.payload.id,
          });
        }),
      ],
      on: {
        [getTeamMemberSuccess.type]: {
          actions: [
            assign({
              selectedTeamMember: ({ event }) => {
                return event.payload.output.profile;
              },
            }),
          ],
        },
        [getTeamMemberFailure.type]: {
          actions: assign({
            error: ({ event }) => event.payload.error,
          }),
        },
        [actions.editSubmit.type]: {
          actions: [
            sendTo(editTeamMemberMachine.id, ({ context, event }) => {
              return editTeamMemberTrigger({
                client: context.client,
                payload: { variables: event.payload.variables },
              });
            }),
          ],
        },
        [editTeamMemberSuccess.type]: [
          {
            guard: ({ event }) =>
              !!event.payload.input.payload.variables.newTags?.length,
            actions: [
              assign({ error: null }),
              ({ self }) => self.send(retryLoadTeamMembers()),
              sendTo(getWorkspaceTagsMachine.id, ({ context, event }) => {
                return getWorkspaceTagsTrigger({
                  client: context.client,
                  workspaceId:
                    event.payload.input.payload.variables.workspaceId!,
                });
              }),
              raise(() => actions.editClose()),
            ],
          },
          {
            guard: ({ event }) =>
              (event.payload.input.payload.variables.newTags?.length || 0) ===
              0,
            actions: [
              assign({ error: null }),
              ({ self }) => self.send(retryLoadTeamMembers()),
              raise(() => actions.editClose()),
            ],
          },
        ],
        [editTeamMemberFailure.type]: {
          actions: assign({
            error: ({ event }) => event.payload.error,
          }),
        },
        [actions.editClose.type]: {
          target: TeamMembersState.List,
          actions: [
            assign({ selectedTeamMember: null }),
            ({ self }) => self.send(retryLoadTeamMembers()),
          ],
        },
        [actions.getPresignedTeamMemberUrl.type]: {
          actions: [
            sendTo(getPreSignedTeamMemberUrlMachine.id, ({ event }) => {
              return getPreSignedTeamMemberUrlTrigger(event.payload);
            }),
          ],
        },
        [actions.uploadTeamMemberImage.type]: {
          actions: [
            sendTo(uploadTeamMemberImageMachine.id, ({ event }) => {
              event = event as ReturnType<
                TeamMemberActionCreators["uploadTeamMemberImage"]
              >;

              return uploadTeamMemberImageTrigger(event.payload);
            }),
          ],
        },
        [actions.deleteTeamMemberImage.type]: {
          actions: [
            sendTo(deleteTeamMemberImageMachine.id, ({ event }) => {
              event = event as ReturnType<
                TeamMemberActionCreators["deleteTeamMemberImage"]
              >;

              return deleteTeamMemberImageTrigger(event.payload);
            }),
          ],
        },
        [getPreSignedTeamMemberUrlSuccess.type]: {
          actions: [
            assign(({ event }) => ({
              selectedTeamMemberImageData: {
                presignedUrl: event.payload.output,
                lastUpdatedTimeStamp: getUnixTime(new Date()),
              },
            })),
          ],
        },
        [getPreSignedTeamMemberUrlFailure.type]: {
          actions: [
            assign(() => ({
              selectedTeamMemberImageData: {
                presignedUrl: null,
                lastUpdatedTimeStamp: null,
              },
            })),
          ],
        },
        [uploadTeamMemberImageSuccess.type]: {
          actions: [
            assign(({ context }) => ({
              selectedTeamMemberImageData: {
                presignedUrl: context.selectedTeamMemberImageData.presignedUrl,
                lastUpdatedTimeStamp: getUnixTime(new Date()),
              },
              error: null,
            })),
          ],
        },
        [uploadTeamMemberImageFailure.type]: {
          actions: [
            assign(() => ({
              selectedTeamMemberImageData: {
                presignedUrl: null,
                lastUpdatedTimeStamp: null,
              },
            })),
          ],
        },
        [deleteTeamMemberImageSuccess.type]: {
          actions: [
            assign(({ context }) => ({
              selectedTeamMemberImageData: {
                presignedUrl: context.selectedTeamMemberImageData.presignedUrl,
                lastUpdatedTimeStamp: getUnixTime(new Date()),
              },
              error: null,
            })),
          ],
        },
        [deleteTeamMemberImageFailure.type]: {
          actions: [
            assign(() => ({
              selectedTeamMemberImageData: {
                presignedUrl: null,
                lastUpdatedTimeStamp: null,
              },
            })),
          ],
        },
      },
    },
    [TeamMembersState.DeleteDialog]: {
      on: {
        [actions.deleteSubmit.type]: {
          actions: [
            sendTo(deleteTeamMemberMachine.id, ({ context, event }) => {
              return deleteTeamMemberTrigger({
                client: context.client,
                payload: { variables: event.payload.variables },
              });
            }),
          ],
        },
        [deleteTeamMemberSuccess.type]: {
          actions: [
            assign({ error: null }),
            ({ self }) => self.send(retryLoadTeamMembers()),
            raise(() => actions.deleteClose()),
          ],
        },
        [deleteTeamMemberFailure.type]: {
          actions: assign({
            error: ({ event }) => event.payload.error,
          }),
        },
        [actions.deleteClose.type]: {
          target: TeamMembersState.List,
          actions: [assign({ teamMemberToBeDeleted: null })],
        },
      },
    },
    [TeamMembersState.BulkInvite]: {
      initial: TeamMemberBulkInviteState.Initial,
      states: {
        [TeamMemberBulkInviteState.Initial]: {
          on: {
            [actions.bulkInviteSubmit.type]: {
              target: TeamMemberBulkInviteState.Parsing,
            },
          },
        },
        [TeamMemberBulkInviteState.Parsing]: {
          invoke: {
            src: fromPromise(({ input }) => {
              const { formData } = input as ReturnType<
                typeof actions.bulkInviteSubmit
              >["payload"];

              return parseXLSXInvites({
                file: formData.file!,
                nameKey: formData.nameKey
                  .split(",")
                  .slice(0, 2)
                  .map((i) => i.trim()) as
                  | [string]
                  | [string, string]
                  | [string, string, string],
                jobTitleKey: formData.jobTitleKey,
                emailKey: formData.emailKey,
                accessKey: formData.accessKey,
                statusKey: formData.statusKey,
                defaultAccess: formData.defaultAccess,
                defaultStatus: formData.defaultStatus,
              });
            }),
            input: ({ event }) => {
              event = event as ReturnType<typeof actions.bulkInviteSubmit>;
              return event.payload;
            },
            onError: {
              target: TeamMemberBulkInviteState.Ready,
              actions: [
                assign({
                  error: ({ event }) => (event.error as any).message,
                }),
              ],
            },
            onDone: {
              target: TeamMemberBulkInviteState.Ready,
              actions: [
                assign({
                  invites: ({ event }) => event.output,
                }),
              ],
            },
          },
        },
        [TeamMemberBulkInviteState.Ready]: {
          on: {
            [actions.bulkInviteSubmit.type]: {
              target: TeamMemberBulkInviteState.Parsing,
              actions: [
                assign({
                  error: null,
                }),
              ],
            },
            [actions.bulkInviteSubmitConfirmation.type]: [
              {
                guard: ({ context, event }) =>
                  !!event.payload.outcome &&
                  !!context.invites &&
                  context.invites.length > 0 &&
                  !context.error,
                actions: [
                  sendTo(bulkInviteMachine.id, ({ context }) => {
                    const { client, invites } = context;
                    if (!invites) return;
                    return bulkInviteTrigger({ invites, client, force: true });
                  }),
                ],
              },
              {
                actions: [
                  assign({
                    invites: null,
                  }),
                ],
              },
            ],
            [bulkInviteFailure.type]: {
              actions: [
                assign({
                  error: ({ event }) => event.payload.error.message,
                  invites: null,
                }),
              ],
            },
            [bulkInviteSuccess.type]: {
              actions: [
                assign({
                  error: null,
                  invites: null,
                }),
              ],
            },
          },
        },
      },
      on: {
        [actions.bulkInviteDialogClose.type]: {
          target: TeamMembersState.List,
          actions: [
            assign({ error: null }),
            sendTo(bulkInviteMachine.id, () =>
              bulkInviteReset({ clean: true })
            ),
            ({ self }) => self.send(retryLoadTeamMembers()),
          ],
        },
      },
    },
  },
  on: {
    [retryLoadTeamMembers.type]: {
      actions: [
        sendTo(teamMembersInfiniteScrollMachine.id, () =>
          retryLoadTeamMembers()
        ),
      ],
    },
    [setLoadTeamMembersCurrentRenderedRange.type]: {
      actions: sendTo(teamMembersInfiniteScrollMachine.id, ({ event }) =>
        setLoadTeamMembersCurrentRenderedRange(event.payload)
      ),
    },
  },
});
