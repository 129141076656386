import { ActorRefFrom, createMachine, assign } from "xstate";
import { createAction, props } from "../utils";
import { fetchMachineFactory, FetchState } from "./fetch-factory";
import { Connection } from "../../types/pagination";

type InfiniteScrollContext<A, I> = {
  actorList: A[];
  items: (I | null)[];
  totalCount: number | null;
  pageSize: number | null;
  startIndex: number | null;
  endIndex: number | null;
  currentActivePage: number;
};

type Pagination = { page: number; pageSize: number };

export function infiniteScrollMachineFactory<
  ID extends string,
  INVOKE_FN_ARGS extends Pagination,
  RETURN_TYPE extends Promise<Connection<any>> = Promise<Connection<any>>,
  ITEM = RETURN_TYPE extends Promise<Connection<infer U>> ? U : never
>({
  id,
  invokeFn,
}: {
  id: ID;
  invokeFn: (arg: INVOKE_FN_ARGS) => RETURN_TYPE;
}) {
  const {
    machine: fetchMachine,
    trigger: fetchTrigger,
    success: fetchSuccess,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    failure: fetchFailure, // TODO: @Miahil please handle this 🙏
    cancel: fetchCancel,
    done: fetchDone,
    retry: retryFetch,
    reset: resetFetch,
  } = fetchMachineFactory({ id, invokeFn });

  const loadAction = createAction(
    `[INFINITE SCROLL] LOAD`,
    props<Parameters<typeof invokeFn>[0]>()
  );

  const setCurrentRenderedRange = createAction(
    `[INFINITE SCROLL] SET CURRENT RENDERED RANGE`,
    props<{ startIndex: number; endIndex: number }>()
  );

  const retryAction = createAction(`[INFINITE SCROLL] RETRY`);

  type FetchMachineActor = ActorRefFrom<typeof fetchMachine>;
  type TYPES = {
    context: InfiniteScrollContext<FetchMachineActor, ITEM>;
    events:
      | ReturnType<typeof loadAction>
      | ReturnType<typeof retryAction>
      | ReturnType<typeof setCurrentRenderedRange>;
    // NOT WORKING FOR NOW
    // | ReturnType<typeof fetchSuccess>
    // | ReturnType<typeof fetchFailure>;
  };

  const machine = createMachine({
    id,
    types: {} as TYPES,
    context: {
      actorList: [],
      items: [],
      totalCount: null,
      pageSize: null,
      startIndex: null,
      endIndex: null,
      currentActivePage: 1,
    },
    on: {
      [loadAction.type]: {
        actions: assign({
          actorList: ({ event, context, spawn }) => {
            const payload = event.payload;
            const { page } = payload;
            const actorIndex = (page || 1) - 1;
            // const pagination = {
            //   offset: actorIndex * pageSize,
            //   limit: pageSize,
            // };

            const existingFetchMachine = context.actorList[actorIndex];

            const fetchMachineActor =
              !!existingFetchMachine &&
              existingFetchMachine.getSnapshot().value !== FetchState.Done
                ? existingFetchMachine
                : spawn(fetchMachine, {
                    id: `fetch-machine-${actorIndex}`,
                  });

            context.actorList[actorIndex] = fetchMachineActor;

            const fetchMachineSnapshot = fetchMachineActor.getSnapshot();
            let areFiltersTheSame = true;
            const filters = Object.entries(payload);
            for (const [key, value] of filters) {
              const prevValue = fetchMachineSnapshot.context.input?.[key];
              areFiltersTheSame = prevValue === value;
              if (!areFiltersTheSame) break;
            }

            if (!areFiltersTheSame || !existingFetchMachine) {
              if (fetchMachineSnapshot.value === FetchState.Fetching) {
                fetchMachineActor.send(fetchCancel());
              }
              fetchMachineActor.send(
                fetchTrigger({
                  force: true,
                  ...payload,
                } as any)
              );
            }

            return context.actorList;
          },
          pageSize: ({ event }) => event.payload.pageSize,
        }),
      },
      [retryAction.type]: {
        actions: [
          ({ context }) => {
            const {
              currentActivePage,
              actorList,
              startIndex: _startIndex,
              endIndex: _endIndex,
            } = context;

            // Note: If setCurrentRenderedRange has not been called
            // we have to reload all the actors not only the ones that
            // are in the viewport
            const isCurrentPageTrustworthy = _startIndex && _endIndex;
            const reloadActors = isCurrentPageTrustworthy
              ? [
                  context.actorList[currentActivePage - 1],
                  context.actorList[currentActivePage],
                  context.actorList[currentActivePage + 1],
                ]
              : context.actorList;

            reloadActors
              .filter((val) => !!val)
              .forEach((a) => a.send(retryFetch()));

            if (!isCurrentPageTrustworthy) return;

            for (const actor of actorList) {
              if (reloadActors.includes(actor)) continue;
              actor.send(resetFetch({ clean: true, cleanInput: true }));
            }
          },
          assign({
            items: ({ context }) => {
              const {
                currentActivePage,
                pageSize,
                items,
                totalCount,
                startIndex: _startIndex,
                endIndex: _endIndex,
              } = context;
              const isCurrentPageTrustworthy = _startIndex && _endIndex;

              if (!isCurrentPageTrustworthy) return items;

              const startIndex = Math.max(
                (currentActivePage - 1) * pageSize! - pageSize!,
                0
              );
              const endIndex = Math.min(
                currentActivePage * pageSize! - 1 + 20,
                totalCount! - 1
              );
              return [
                ...items.slice(0, startIndex).map(() => null),
                ...items.slice(startIndex, endIndex + 1),
                ...items.slice(endIndex + 1).map(() => null),
              ];
            },
          }),
        ],
      },
      [fetchSuccess.type]: {
        actions: [
          assign({
            actorList: ({ event, context }) => {
              const successEvent = event as ReturnType<typeof fetchSuccess>;
              const pageSize = successEvent.payload.input.pageSize;
              const totalCount = (
                successEvent.payload.output as Connection<ITEM>
              ).pageInfo.total;
              const totalPages = Math.ceil(totalCount / pageSize);
              const activeActors = context.actorList.slice(0, totalPages);

              const doneActors = context.actorList.slice(totalCount);
              doneActors.forEach((actor: any) => actor.send(fetchDone()));
              return activeActors;
            },
            items: ({ event, context }) => {
              const successEvent = event as ReturnType<typeof fetchSuccess>;
              const { page, pageSize } = successEvent.payload.input;
              const offset = ((page || 1) - 1) * pageSize;
              const { nodes } = successEvent.payload.output as Connection<ITEM>;
              const { items } = context;
              for (let i = 0; i < nodes.length; i++) {
                items[Math.max(offset, 0) + i] = nodes[i];
              }
              return items;
            },
            totalCount: ({ event }) => {
              const successEvent = event as ReturnType<typeof fetchSuccess>;
              return (successEvent.payload.output as Connection<ITEM>).pageInfo
                .total;
            },
          }),
        ],
      },
      [setCurrentRenderedRange.type]: {
        actions: [
          assign({
            startIndex: ({ event }) => event.payload.startIndex,
            endIndex: ({ event }) => event.payload.endIndex,
            currentActivePage: ({
              event: {
                payload: { startIndex },
              },
              context: { pageSize },
            }) => Math.floor(startIndex / (pageSize || 1)) + 1,
          }),
        ],
      },
    },
  });
  return {
    machine,
    loadAction,
    retryAction,
    setCurrentRenderedRange,
  };
}
