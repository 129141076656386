function parseHTML(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return doc;
}

function fetchLatestIndexHTML() {
  return fetch("/index.html", { cache: "no-cache" }).then((res) =>
    res.ok ? res.text() : Promise.reject(new Error("Error fetching index.html"))
  );
}

function extractAssetUrls(doc: Document) {
  const scripts = Array.from(doc.querySelectorAll("script[src]")).map(
    (script) => script.getAttribute("src")
  );
  const links = Array.from(doc.querySelectorAll('link[rel="stylesheet"]')).map(
    (link) => link.getAttribute("href")
  );
  return [...scripts, ...links];
}

function assetsHaveChanged(
  currentAssets: (string | null)[],
  latestAssets: (string | null)[]
) {
  if (currentAssets.length !== latestAssets.length) {
    return true;
  }
  const sortedCurrent = currentAssets.sort();
  const sortedLatest = latestAssets.sort();
  for (let i = 0; i < sortedCurrent.length; i++) {
    if (sortedCurrent[i] !== sortedLatest[i]) {
      return true;
    }
  }
  return false;
}

export function checkForApplicationUpdate() {
  return fetchLatestIndexHTML()
    .then(parseHTML)
    .then(extractAssetUrls)
    .then(
      (latestAssets) =>
        [
          latestAssets.filter((asset) =>
            asset ? /static\/(js|css)/.test(asset) : false
          ),
          extractAssetUrls(document).filter((asset) =>
            asset ? /static\/(js|css)/.test(asset) : false
          ),
        ] as const
    )
    .then(([latestAssets, currentAssets]) =>
      assetsHaveChanged(currentAssets, latestAssets)
    )
    .catch((err) => {
      console.error(
        "Error fetching index.html for application update check",
        err
      );
      return false;
    });
}
