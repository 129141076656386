import { ActorRef } from "xstate";
import { createAction, props } from "../../utils";
import { SessionStateResult } from "../../../apollo-graphql/types/results/session-state-result";
import { KickReason } from "../../../types/kick-reason";
import { WorkshopDisconnectType } from "../../../types/enums/workshop-disconnect-type";

export const startWorkshop = createAction(
  "[WORKSHOP] Start",
  props<{
    currentProfileId: string;
    sessionId: string;
    uuid: string;
    journeyId: string;
    currentProfileEmail: string;
    currentProfileWorkspaceId: string;
  }>()
);

export const workshopSubscribe = createAction(
  "[WORKSHOP] Subscribe",
  props<{
    sessionId: string;
    workshopActor: ActorRef<any, any>;
    uuid: string;
  }>()
);

export const workshopJoin = createAction(
  "[WORKSHOP] Join",
  props<{ sessionId: string; uuid: string }>()
);
export const workshopDisconnect = createAction(
  "[WORKSHOP] Disconnect",
  props<{
    sessionId: string;
    type: WorkshopDisconnectType;
    intended?: boolean;
    reason?: any;
  }>()
);

export const workshopReady = createAction("[WORKSHOP] Ready");
export const workshopEnd = createAction(
  "[WORKSHOP] End",
  props<{ journeyId: string; currentProfileEmail: string }>()
);
export const workshopEnded = createAction("[WORKSHOP] Ended");

export const workshopSubscriptionData = createAction(
  "[WORKSHOP] Workshop subscription data",
  props<{ data: SessionStateResult }>()
);

export const workshopSubscriptionDataTransition = createAction(
  "[WORKSHOP] Workshop subscription data transition",
  props<{ transition: number }>()
);

export const workshopReadyToStart = createAction(
  "[WORKSHOP] Workshop ready to start",
  props<{ sessionId: string }>()
);

export const workshopSetActivityValue = createAction(
  "[WORKSHOP] Workshop set activity value",
  props<{
    sessionId: string;
    activityId: string;
    value: string;
    markAsReady?: boolean;
  }>()
);

export const workshopSetActivityReady = createAction(
  "[WORKSHOP] Workshop set activity ready",
  props<{ sessionId: string; activityId: string }>()
);

export const workshopParticipantChange = createAction(
  "[WORKSHOP] Workshop participant change",
  props<{ participantIds: string[] } | { refetchParticipantIds: string[] }>()
);

export const setTransitionIntervalId = createAction(
  "[WORKSHOP] Set transition interval id",
  props<{ transitionIntervalId: number | null }>()
);

export const pushDelayedStateData = createAction(
  "[WORKSHOP] Push delayed state data",
  props<{ data: SessionStateResult }>()
);
export const clearDelayedStateData = createAction(
  "[WORKSHOP] Clear delayed state data"
);

export const kick = createAction(
  "[WORKSHOP] Kick",
  props<{ reason: KickReason }>()
);

export const getJourney = createAction(
  "[WORKSHOP] Get Journey",
  props<{ journeyId: string }>()
);

export const getSlotsForCurrentProfile = createAction(
  "[WORKSHOP] Get Slots For Current Profile",
  props<{ currentProfileEmail: string }>()
);

export const timeout = createAction("[WORKSHOP] Timeout");
